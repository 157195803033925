import React from 'react'

interface Props {
  size?: number
  color?: string
}

const QualificationIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 22}
      height={size ?? 17}
      fill="none"
      viewBox={'0 0 22 17'}
    >
      <path
        d="m21 5.5-10-4-10 4 10 4 10-4Zm0 0v6M5 7.1v5.4c0 .796.632 1.559 1.757 2.121 1.126.563 2.652.88 4.243.88 1.591 0 3.117-.317 4.243-.88C16.368 14.06 17 13.296 17 12.501V7.1"
        stroke={color ?? '#D9D9D9'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QualificationIcon
