import React, { useEffect, useMemo, useState } from 'react'
import styles from './../assesmentCard/assesmentCard.module.scss'
import colors from '../../styles/variables.module.scss'
import BagIcon from '../../icons/bag.icon'
import CustomButton from '../shared/CustomButton/customButton'
import { useNavigate } from 'react-router-dom'
import LocationIcon from '../../icons/location.icon'
import profilePic from '../../images/avatarSqure.png'
import { CandidateDetails, JobDetails } from '../../services/job/job.res.model'
import { UploadedFile } from '../../services/file/file.model'
import JobService from '../../services/job/job.service'

type ResourceProps = {
  id: string
  description: string
  candidate: CandidateDetails
  pageIsLoading: boolean
  job: JobDetails | undefined
}

function ResourceCard({
  id,
  description,
  candidate,
  pageIsLoading,
  job,
}: ResourceProps) {
  const navigate = useNavigate()

  const [profileImage, setProfileImage] = useState<UploadedFile | null>(null)

  const jobService = useMemo(() => new JobService(), [])

  useEffect(() => {
    jobService
      .getCandidateProfileImage({ id: candidate.id })
      .then(setProfileImage)
  }, [jobService, candidate])

  return (
    <div
      className={styles.topthreeCard}
      style={{ borderColor: colors.gray200 }}
    >
      <h4>{`0${id}`}.</h4>
      <div className={'d-flex align-items-center'}>
        <img
          src={profileImage ? profileImage.contents : profilePic}
          alt={'avatar'}
          className={styles.resourcePic}
        />
        <div className={'ms-4'}>
          <div className={'d-flex mt-5'}>
            {candidate.location && (
              <div className={'d-flex me-4'}>
                <LocationIcon />
                <h6 className={'ms-3 gray-color-text'}>{candidate.location}</h6>
              </div>
            )}
            {candidate.location && (
              <div className={`${styles.borderLeft}`}>
                <BagIcon />
                <h6 className={'ms-3 gray-color-text'}>
                  <span className={'text-normal'}>Availability - </span>
                  {candidate.notice_period_days === 0
                    ? 'Immediately'
                    : `${Math.ceil(candidate.notice_period_days / 7)} week${Math.ceil(candidate.notice_period_days / 7) > 1 ? 's' : ''}`}
                </h6>
              </div>
            )}
          </div>
          <div className={styles.discription}>
            <h6
              className={`gray-color-text text-normal mt-5 ${styles.widthDescription}`}
            >
              <span className={styles.elipsisDes}>{description}</span>
              <span
                className={'text-bold pointer'}
                onClick={() => {
                  if (!pageIsLoading)
                    navigate('/profileOverview', {
                      state: { candidate, profileImage, job },
                    })
                }}
              >
                Read More
              </span>
            </h6>
            <div className={'ms-4'}>
              <CustomButton
                text={'View Profile'}
                className={styles.whiteBtn}
                onClick={() => {
                  if (!pageIsLoading)
                    navigate('/profileOverview', {
                      state: { candidate, profileImage, job },
                    })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceCard
