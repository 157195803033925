import { useContext, useEffect, useState } from 'react'
import ProfLogo from '../../images/BBB Logo_Professional.jpg'
import OrgLogo from '../../images/BBB Logo_Organisation.jpg'
import styles from './TopNavigation.module.scss'
import AvatarDropdown, {
  DropdownDataList,
} from '../shared/avatarDropdown/avatarDropdown'
import DownArrowIcon from '../../icons/downArrow.icon'
import ManIcon from '../../icons/man.icon'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import HamburgerIcon from '../../icons/hamburger.icon'
import CustomModal from '../shared/customModal/customModal'
import UpdateAccountProfile from '../organizationModals/updateAccountProfile'
import UpdateEmail from '../organizationModals/updateEmail'
import UpdatePassword from '../organizationModals/updatePassword'
import UpdateCompanyDetails from '../organizationModals/updateCompanyDetails'
import DeleteConfirmation from '../organizationModals/deleteConfirmation'
import { UserContext } from '../../context'

type TopNaveProps = {
  dashboard?: boolean
  tabValue?: string
  organization?: boolean
  organizationLogo?: boolean
  isReview?: boolean
}

const TopNavigation: React.FC<TopNaveProps> = ({
  dashboard,
  tabValue,
  organization,
  organizationLogo,
  isReview,
}) => {
  const navigate = useNavigate()
  const userData = useContext(UserContext)
  const dataList = [{ value: '4', text: 'Sign Out' }]
  const orgDataList = [
    { value: '0', text: 'Account Profile' },
    { value: '1', text: 'Organisation Details' },
    { value: '1', text: 'Update Email' },
    { value: '2', text: 'Update Password' },
    { value: '4', text: 'Sign Out' },
  ]
  const dashboardList = [
    { value: '0', text: 'Dashboard' },
    { value: '1', text: 'Profile' },
  ]
  const dashboardListOrg = [
    { value: '2', text: 'Roles' },
    { value: '3', text: 'Organisations' },
  ]

  const [name, setName] = useState('')
  const [tab, setTab] = useState(tabValue ?? '')
  const [accountProfile, setAccountProfile] = useState(false)
  const [updateEmail, setUpdateEmail] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [companyDetails, setCompanyDetails] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setName(userData.userData?.full_name || 'User')
  }, [userData])

  const handleDropDownClick = async function (item: DropdownDataList) {
    if (item.value === '4' && item.text === 'Sign Out') {
      localStorage.removeItem('masterData')
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenExpiration')
      localStorage.removeItem('login_form')
      await Auth.signOut()

      navigate(
        '/SignIn?type=' +
          (organization ? 'organizationSignIn' : 'candidateSignIn')
      )
    }
    if (item.text === 'Account Profile') {
      setAccountProfile(true)
    }
    if (item.text === 'Update Email') {
      setUpdateEmail(true)
    }
    if (item.text === 'Update Password') {
      setUpdatePassword(true)
      setOpen(true)
    }
    if (item.text === 'Organisation Details') {
      setCompanyDetails(true)
    }
    if (item.text === 'Delete Account') {
      setDeleteAccount(true)
    }
  }
  const handleTab = (index: string) => {
    setTab(index)
    if (index === '0') {
      if (userData.isAssessmentCompleted) {
        navigate('/dashboard/TopMatches')
      } else {
        navigate('/dashboard')
      }
    }
    if (index === '1') {
      navigate('/Profile')
    }
  }
  const handleTabOrg = (index: string) => {
    setTab(index)
    if (index === '2') {
      navigate('/jobs')
    } else {
      navigate('/companies')
    }
  }

  const handleCloseModal = (val: boolean) => {
    setCompanyDetails(val)
    setAccountProfile(val)
    setUpdateEmail(val)
  }

  return (
    <div className={styles.header}>
      <div
        className={'d-flex align-items-center justify-content-between w-100'}
      >
        {organizationLogo ? (
          <>
            <div className={styles.logo}>
              <img
                src={OrgLogo}
                alt="react logo"
                className={styles.logoImage}
              />
            </div>
            <div className={styles.mobileLogo}>
              <img
                src={OrgLogo}
                alt={'mobileLogo'}
                style={{ width: '130px' }}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.logo}>
              <img
                src={ProfLogo}
                alt="react logo"
                className={styles.logoImage}
              />
            </div>
            <div className={styles.mobileLogo}>
              <img
                src={ProfLogo}
                alt={'mobileLogo'}
                style={{ width: '130px' }}
              />
            </div>
          </>
        )}
        {!isReview && (
          <div className={'d-flex align-items-center '}>
            {dashboard && (
              <div className={'d-flex align-items-center'}>
                {dashboardList.map((value, index) => (
                  <div
                    className={styles.sideMenuButton}
                    key={index}
                    onClick={() => handleTab(value.value)}
                  >
                    <h6
                      className={
                        'text-normal text-teritary-color pointer ms-5 me-5'
                      }
                    >
                      {value.text}
                      {tab === value.value && (
                        <motion.div
                          className={styles.indicator}
                          layoutId="left-indicator"
                        />
                      )}
                    </h6>
                  </div>
                ))}
              </div>
            )}
            {organization && (
              <div className={'d-flex align-items-center'}>
                {dashboardListOrg.map((value, index) => (
                  <div
                    className={styles.sideMenuButton}
                    key={index}
                    onClick={() => handleTabOrg(value.value)}
                  >
                    <h6
                      className={
                        'text-normal text-teritary-color pointer ms-5 me-5'
                      }
                    >
                      {value.text}
                      {tab === value.value && (
                        <motion.div
                          className={styles.indicator}
                          layoutId="left-indicator"
                        />
                      )}
                    </h6>
                  </div>
                ))}
                {/*<div>*/}
                {/*    <BellIcon/>*/}
                {/*</div>*/}
              </div>
            )}
            <div className={styles.dropDown}>
              <AvatarDropdown
                dataList={organization ? orgDataList : dataList}
                name={'topNav'}
                getSelectedItem={(item: DropdownDataList) =>
                  handleDropDownClick(item)
                }
              >
                <div className={'d-flex align-items-center '}>
                  <h6 className={'text-almostBlack me-3 mt-2'}>
                    {name.split(' ')[0]}
                  </h6>
                  <ManIcon />
                  <div className={'ms-2'}>
                    <DownArrowIcon />
                  </div>
                </div>
              </AvatarDropdown>
            </div>
            {organization && (
              <div className={styles.mobileMenu}>
                <AvatarDropdown
                  icon={<HamburgerIcon />}
                  dataList={
                    organization
                      ? dashboardListOrg
                      : [
                          { value: '0', text: 'Dashboard' },
                          { value: '1', text: 'Profile' },
                        ]
                  }
                  getSelectedItem={
                    organization
                      ? (item: DropdownDataList) => handleTabOrg(item.value)
                      : (item: DropdownDataList) => handleTab(item.value)
                  }
                />
              </div>
            )}
            {dashboard && (
              <div className={styles.mobileMenu}>
                <AvatarDropdown
                  icon={<HamburgerIcon />}
                  dataList={[
                    { value: '0', text: 'Dashboard' },
                    { value: '1', text: 'Profile' },
                  ]}
                  getSelectedItem={
                    organization
                      ? (item: DropdownDataList) => handleTabOrg(item.value)
                      : (item: DropdownDataList) => handleTab(item.value)
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>

      <CustomModal
        open={accountProfile}
        onCloseModal={(val) => setAccountProfile(val)}
        title={' Account Profile'}
      >
        <UpdateAccountProfile onCancel={() => handleCloseModal(false)} />
      </CustomModal>
      <CustomModal
        open={updateEmail}
        onCloseModal={(val) => setUpdateEmail(val)}
        title={'Update Email'}
      >
        <UpdateEmail onCancel={() => handleCloseModal(false)} />
      </CustomModal>
      {open && (
        <CustomModal
          title={'Update Password'}
          open={updatePassword}
          onCloseModal={(val) => {
            setUpdatePassword(val)
            setOpen(val)
          }}
        >
          <UpdatePassword
            onSuccess={() => {}}
            onFailure={() => handleCloseModal(false)}
            closeModal={() => setOpen(false)}
          />
        </CustomModal>
      )}
      <CustomModal
        open={companyDetails}
        onCloseModal={(val) => setCompanyDetails(val)}
        title={'Organisation Details'}
      >
        <UpdateCompanyDetails onCancel={() => handleCloseModal(false)} />
      </CustomModal>
      <CustomModal
        title={'Are You Sure ?'}
        open={deleteAccount}
        onCloseModal={(val) => setDeleteAccount(val)}
        hideCloseButton={true}
      >
        <DeleteConfirmation closeModal={() => setDeleteAccount(false)} />
      </CustomModal>
    </div>
  )
}

export default TopNavigation
