import React from 'react'

interface Props {
  size?: number
  color?: string
}

const BlackStarIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 22}
      height={size ? size - 1 : 21}
      fill="none"
      viewBox={'0 0 22 21'}
    >
      <path
        stroke={color ?? '#1F1F1F'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m11 16.75-6.172 3.245 1.179-6.873-5-4.867 6.9-1 3.086-6.253 3.086 6.253 6.9 1-5 4.867 1.179 6.873L11 16.75Z"
      />
    </svg>
  )
}

export default BlackStarIcon
