import React from 'react'

interface Props {
  size?: number
  color?: string
}

const ExperienceIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 20 20'}
    >
      <path
        d="M6 5.5v-2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-4 5v.01m-9 .99a20 20 0 0 0 18 0m-16-6h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2Z"
        stroke={color ?? '#D9D9D9'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExperienceIcon
