import React, { useContext, useEffect, useState } from 'react'
import styles from './JobInner.module.scss'
import BagIcon from '../../icons/bag.icon'
import WalkingManIcon from '../../icons/walkingMan.icon'
import ClockIcon from '../../icons/clock.icon'
import CustomButton from '../shared/CustomButton/customButton'
import TopNavigation from '../topNavigation/topNavigation'
import LeftArrow from '../../icons/leftArrow.icon'
import DegreeCapIcon from '../../icons/degreeCap.icon'
import DescriptionIcon from '../../icons/discription.icon'
import colors from '../../styles/variables.module.scss'
import SendIcon from '../../icons/send.icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

function JobInner() {
  const navigate = useNavigate()
  const location = useLocation()
  const masterData = useContext(MasterDataContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [commitment, setCommitment] = useState('')
  const [workType, setWorkType] = useState('')
  const [hoursPerWeek, setHoursPerWeek] = useState('')
  const [taskExpertise, setTaskExpertise] = useState([])
  const [educationRequired, setEducationRequired] = useState('')
  const [educationLevel, setEducationLevel] = useState<any>()
  const [experienceRequired, setExperienceRequired] = useState('')
  const [startDate, setStartDate] = useState('')
  useEffect(() => {
    if (!location.state) {
      navigate('/')
      return
    }
    setTitle(location.state.position)
    setDescription(location.state.description)
    setCommitment(location.state.commitment)
    setWorkType(location.state.workType)
    setHoursPerWeek(location.state.hoursPerWeek)
    setTaskExpertise(location.state.taskExpertise)
    setEducationRequired(location.state.educationRequired)
    setExperienceRequired(location.state.experienceRequired)
    setStartDate(location.state.startDate)
  }, [navigate, location.state, setDescription])

  useEffect(() => {
    if (masterData?.masterData) {
      if (educationRequired) {
        setEducationLevel(
          masterData.masterData.filter(
            (fd: any) => fd.id === educationRequired
          )[0].value
        )
      } else {
        setEducationLevel('')
      }
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData, educationRequired])

  return (
    <>
      <TopNavigation dashboard />
      <div className={styles.content}>
        <div className={styles.card}>
          <CustomButton
            type={'button'}
            text={'Go Back'}
            icon={<LeftArrow />}
            iconSide={'left'}
            className={styles.whiteBtn}
            onClick={() => navigate('/dashboard/TopMatches')}
          />
          <div className={'d-flex align-items-center mt-5 flex-wrap'}>
            <h4 className={'text-bolder text-almostBlack me-4'}>{title}</h4>
            {taskExpertise.map((value, index) => (
              <div
                className={styles.payableTag}
                key={index}
                style={{ marginRight: '5px', marginBottom: '5px' }}
              >
                <h6>{value}</h6>
              </div>
            ))}
          </div>
          <div className={`d-flex mt-5 ${styles.borderBottom}`}>
            <div className={'d-flex me-4'}>
              <BagIcon />
              <h6 className={'ms-3 gray-color-text'}>{commitment}</h6>
            </div>
            <div className={styles.borderClass}>
              <WalkingManIcon />
              <h6 className={'ms-3 gray-color-text'}>{workType}</h6>
            </div>
            <div className={'d-flex ms-4'}>
              <ClockIcon />
              <h6 className={'ms-3 gray-color-text'}>
                {hoursPerWeek} Hrs per Week
              </h6>
            </div>
          </div>
          <div className={`mt-5 ${styles.borderBottom}`}>
            <div className={'d-flex align-items-center mb-3'}>
              <DegreeCapIcon />
              <h6 className={'ms-3 gray-color-text '}>Education</h6>
            </div>
            <h6 className={'darkgray-text'}>{educationLevel}</h6>
          </div>
          <div className={styles.borderBottom}>
            <div className={`mt-5 d-flex w-75 justify-content-between`}>
              <div>
                <div className={'d-flex align-items-center mb-3'}>
                  <BagIcon color={colors.textLightColor} />
                  <h6 className={'ms-3 gray-color-text '}>Experience</h6>
                </div>
                <h6 className={'darkgray-text'}>{experienceRequired} Years</h6>
              </div>
              <div>
                <div className={'d-flex align-items-center mb-3'}>
                  <SendIcon color={colors.textLightColor} />
                  <h6 className={'ms-3 gray-color-text '}>Start Date</h6>
                </div>
                {new Date(startDate) < new Date() ? (
                  <h6 className={'darkgray-text'}>As Soon As Possible</h6>
                ) : (
                  <h6 className={'darkgray-text'}>{startDate}</h6>
                )}
              </div>
            </div>
          </div>
          <div className={`mt-5`}>
            <div className={'d-flex align-items-center'}>
              <DescriptionIcon />
              <h6 className={'ms-3 gray-color-text'}>Description</h6>
            </div>
            <h6 className={'darkgray-text text-normal mt-5 mb-5'}>
              {description}
            </h6>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default JobInner
