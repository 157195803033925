import React, { useMemo, useState } from 'react'
import { Formik, Form, FormikValues, FormikHelpers } from 'formik'
import styles from './sinIn.module.scss'
import * as Yup from 'yup'
import CustomInputField from '../shared/customInputField/CustomInputField'
import CustomButton from '../shared/CustomButton/customButton'
import SuccessIcon from '../../icons/success.icon'
import UserService from '../../services/user/user.service'
import LoadingService from '../../services/loading/loading.service'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import BasicStyles from './../gettingStart/GettingStart.module.scss'

type ForgotPasswordProps = {
  email: string
  confirmationCode?: string
  newPassword: string
  confirmPassword: string
}

type RequestType = {
  success: boolean
  message: string
}

function ForgotModal() {
  const [isLoading, setIsLoading] = useState(false)

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const initialVal: ForgotPasswordProps = useMemo(() => {
    return {
      email: '',
      newPassword: '',
      confirmPassword: '',
      code: '',
    }
  }, [])

  const [type, setType] = useState<
    'EnterEmail' | 'NewPassword' | 'Success' | 'Failed'
  >('EnterEmail')

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<ForgotPasswordProps>
  ) => {
    try {
      if (values) {
        const payload: ForgotPasswordProps = {
          email: values.email,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          confirmationCode: values.code,
        }
        const res: RequestType = await loadingService.await(
          userService.changePassword(payload)
        )

        if (res) {
          if (res.success) {
            setType('Success')
          } else {
            formikHelpers.setFieldError('code', res.message)
          }
        }
      }
    } catch (error) {
      setType('Failed')
    }
  }

  const onEmailSubmission = async (
    values: FormikValues,
    setFieldError: FormikHelpers<ForgotPasswordProps>['setFieldError']
  ) => {
    setIsLoading(true)
    try {
      if (values.email) {
        const checkEmail: RequestType = await loadingService.await(
          userService.checkEmailExists(values.email)
        )

        if (checkEmail.success) {
          const res: RequestType = await loadingService.await(
            userService.changePasswordRequest(values.email)
          )

          if (res.success) {
            setIsLoading(false)
            setType('NewPassword')
          } else {
            setFieldError('email', res.message)
          }
        } else {
          setIsLoading(false)
          setFieldError('email', checkEmail.message)
        }
      }
    } catch (error) {
      setIsLoading(false)
      setFieldError('email', 'Something went wrong please try again.')
      // setType('NewPassword')
    }
  }
  const validationSchema = Yup.object().shape({
    // validation for email field value
    email: Yup.string().required('Please enter your email address.'),
    // validation for password field values
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).+$|^(?=.*[a-z])(?=.*\d).+$|^(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).+$|^(?=.*[A-Z])(?=.*\d).+$|^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).+$|^(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).+$/,
        'Password must contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols'
      )
      .required('Please enter your password'),
    confirmPassword: Yup.string()
      .notOneOf(
        [Yup.ref('currentPassword')],
        'Confirm password cannot be the same as the current password'
      )
      .required('Please confirm your password')
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match.'),
    code: Yup.string().required('Please enter the code sent to your email.'),
  })

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange
        >
          {({ values, setFieldValue, errors, setFieldError }) => (
            <Form className={styles.modal}>
              <div className={styles.w75}>
                {type === 'EnterEmail' && (
                  <>
                    <h3>Forgot Password</h3>
                    <div className={styles.formBox}>
                      <h6 className="mb-3 mt-4">
                        Enter the email associated to your account
                      </h6>
                      <CustomInputField
                        placeholder={'Enter Email'}
                        name={'userEmail'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('email', e.target.value)
                        }
                        disabled={isLoading}
                      />
                      {errors.email && (
                        <div className={styles.error}>{errors.email}</div>
                      )}
                      <div
                        className={`mt-4 w-100 d-flex justify-content-end align-items-center `}
                      >
                        <CustomButton
                          type="button"
                          text="Continue"
                          className={styles.widthBtn}
                          onClick={() =>
                            onEmailSubmission(values, setFieldError)
                          }
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
                {type === 'NewPassword' && (
                  <>
                    <h3>New Password</h3>
                    <div className={'mt-4'}>
                      <CustomInputField
                        type={'password'}
                        name={'newPassword'}
                        placeholder={'Enter New Password'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('newPassword', e.target.value)
                        }
                        disabled={isLoading}
                      />
                      <div className={`mt-2 ${BasicStyles.borderClass}`}>
                        <CustomInputField
                          placeholder={'Confirm Password'}
                          name={'confirmPassword'}
                          type={'password'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('confirmPassword', e.target.value)
                          }
                          disabled={isLoading}
                        />
                      </div>
                      <h6 className={'mt-2'}>
                        *Please enter the code that has just been sent to your
                        email
                      </h6>
                      <div className={'mt-2'}>
                        <CustomInputField
                          placeholder={'Enter Code'}
                          name={'code'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('code', e.target.value)
                          }
                          disabled={isLoading}
                        />
                      </div>
                      <div
                        className={
                          'mt-4 d-flex justify-content-end align-items-center'
                        }
                      >
                        <CustomButton
                          type={'submit'}
                          text={'Submit'}
                          className={styles.widthBtn}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
                {type === 'Success' && (
                  <>
                    <h3>Success</h3>
                    <div className={'d-flex align-items-center'}>
                      <div>
                        <SuccessIcon />
                      </div>
                      <h4 className={'text-success ms-3 mt-3'}>
                        Your password Changed.
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
export default ForgotModal
