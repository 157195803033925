import React from 'react'

interface Props {
  size?: number
  color?: string
}

const LinkedInIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 19} height={size ?? 18} fill="none">
      <path
        d="M5.5 8v5m0-8v.01m4 7.99V8m4 5v-3a2 2 0 1 0-4 0m-6-9h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2Z"
        stroke={color ?? '#9CA3AF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkedInIcon
