import React from 'react'

interface Props {
  size?: number
  color?: string
}

const VisaIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 22} height={size ?? 19} fill="none">
      <path
        d="M2 18h18m-6-9.915h5a2 2 0 0 1 0 4H4l-3-6h3l2 2h3l-2-7h3l4 7Z"
        stroke={color ?? '#9CA3AF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VisaIcon
