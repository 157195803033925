import React from 'react'

interface Props {
  size?: number
  color?: string
}

const rightArrow: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 8} height={size ?? 14} fill="none" viewBox={'0 0 8 14'}>
      <path
        d="m1 1 6 6-6 6"
        stroke={color ?? '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default rightArrow
