import {
  GetFileResponse,
  UploadedFile,
  UploadedFileResponse,
} from '../file/file.model'
import FileService from '../file/file.service'
import HttpService from '../http/http.service'
import { SearchOrgRequest } from '../organization/organization.req.model'
import { GetOrgResponse } from '../organization/organization.res.model'
import { GetProfileImageResponse } from '../user/user.res.model'
import UserService from '../user/user.service'
import { CreateStatus } from './job.model'
import {
  CandidateSearchRequest,
  JobCostRequest,
  JobModifyRequest,
  JobSearchRequest,
} from './job.req.model'
import {
  GetJobResponse,
  GetOrgJobsResponse,
  GetUserJobsResponse,
  JobCostResponse,
  JobCreateResponse,
  Top3CandidatesResponse,
} from './job.res.model'

export default class JobService {
  constructor(
    private readonly httpService: HttpService = new HttpService(),
    private readonly userService: UserService = new UserService(),
    private readonly fileService: FileService = new FileService()
  ) {}

  // Get job details
  public async getJob(params: JobSearchRequest): Promise<GetJobResponse> {
    const res = await this.httpService.sendGetRequest<GetJobResponse>(
      'jobs/get?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
    if (res === null) {
      return {
        status: 'error',
        result: null,
      }
    }
    return res
  }

  // Post a new job
  public async postJob(
    params: SearchOrgRequest,
    data: JobModifyRequest
  ): Promise<JobCreateResponse | null> {
    return this.httpService.sendPostRequest(
      'jobs/create?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      data
    )
  }

  // Edit an existing job
  public async editJob(
    params: JobSearchRequest,
    data: JobModifyRequest
  ): Promise<JobCreateResponse | null> {
    return this.httpService.sendPostRequest(
      'jobs/edit?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      data
    )
  }

  // Get the organization jobs from the backend
  public async getOrgJobs(
    params: SearchOrgRequest
  ): Promise<GetOrgJobsResponse> {
    const res = await this.httpService.sendGetRequest<GetOrgJobsResponse>(
      'jobs/org_get?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
    if (res === null) {
      return {
        status: 'error',
        result: [],
      }
    }
    return res
  }

  // Get the user jobs from the backend
  public async getUserJobs(): Promise<GetUserJobsResponse | any> {
    const res = await this.httpService.sendGetRequest<GetUserJobsResponse>(
      'jobs/user_get',
      await this.userService.getUserToken()
    )
    if (res === null) {
      return {
        status: 'error',
        result: [],
      }
    }
    return res
  }

  // Get the hiring company for the job
  public async getHiringCompany(
    params: JobSearchRequest
  ): Promise<GetOrgResponse> {
    try {
      const res = await this.httpService.sendGetRequest<GetOrgResponse>(
        'jobs/company?' + new URLSearchParams(params),
        await this.userService.getUserToken()
      )
      return res
    } catch (e) {
      throw e
    }
  }

  // Run the costing model
  public async costJob(params: JobCostRequest): Promise<JobCostResponse> {
    const res = await this.httpService.sendGetRequest<JobCostResponse>(
      'jobs/cost?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
    if (res === null) {
      return {
        payroll: CreateStatus.Fail,
        banking: CreateStatus.Fail,
        ar: CreateStatus.Fail,
        ap: CreateStatus.Fail,
        eom: CreateStatus.Fail,
        eofy: CreateStatus.Fail,
        bas: CreateStatus.Fail,
        total: CreateStatus.Fail,
      }
    }
    return res
  }

  // Get the top 3 candidates
  public async getTop3Candidates(
    params: JobSearchRequest
  ): Promise<Top3CandidatesResponse | null> {
    return this.httpService.sendGetRequest(
      'matches/candidates?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  //get hiring processes
  public async getHiringProcesses(
    jobId: string
  ): Promise<Top3CandidatesResponse | null> {
    return this.httpService.sendGetRequest(
      'hiring/get-job-processes?' + new URLSearchParams({ id: jobId }),
      await this.userService.getUserToken()
    )
  }

  // Get a candidate profile image
  public async getCandidateProfileImage(
    params: CandidateSearchRequest
  ): Promise<UploadedFile | null> {
    try {
      const image =
        await this.httpService.sendGetRequest<GetProfileImageResponse>(
          'candidate/get-image?' + new URLSearchParams(params),
          await this.userService.getUserToken()
        )
      return await this.fileService.readBackendImage(image)
    } catch (err) {
      throw err
    }
  }

  // Get/upload a job description
  public async getJobDescription(
    params: JobSearchRequest
  ): Promise<GetFileResponse | null> {
    return this.httpService.sendGetRequest(
      'jobs/document/get?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  public async uploadJobDescription(
    params: JobSearchRequest,
    file: UploadedFile
  ): Promise<UploadedFileResponse | null> {
    if (!file.original) {
      return null
    }
    return this.httpService.sendPostRequest(
      'jobs/document/upload?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      this.fileService.attachToForm(file.original)
    )
  }

  public async getPreSignRoleDescFile(
    params: JobSearchRequest
  ): Promise<UploadedFileResponse | null> {
    return this.httpService.sendGetRequest(
      `jobs/get_pre_sign_url_file?` + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  // implement backend method get/candidate/referee/comments to get the referee comments while passing the candidate id
  public async getRefereeComments(candidateID: string): Promise<any> {
    return this.httpService.sendGetRequest(
      'jobs/get/candidate/referee/comments?' +
        new URLSearchParams({ candidateID }),
      await this.userService.getUserToken()
    )
  }
}
