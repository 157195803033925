import React from 'react'

interface Props {
  size?: number
  color?: string
}

const BagIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 19}
      fill={'none'}
      viewBox={'0 0 20 19'}
    >
      <path
        d="M6 5V3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-4 5v.01M1 11a20 20 0 0 0 18 0M3 5h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2Z"
        stroke={color ?? '#636363'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BagIcon
