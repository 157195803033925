import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../shared/customInputField/CustomInputField'
import { Formik, Form } from 'formik'
import { Qualification } from '../profile/educationProfile'
import * as Yup from 'yup'
import CustomSearch from '../shared/listSearch/listSearch'
import YearPicker from '../shared/calender/YearPicker'
import moment from 'dayjs'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const listMonths = months.map((value) => {
  return { value, text: value }
})

export type AddEducationModalProps = {
  qualification: Qualification | null
  onSubmit: (data: Qualification) => void
  closeModal: () => void
}

type QualificationInput = {
  institution: string
  qualification: string
  field_of_study: string
  startMonth: string
  startYear: number
  endMonth: string
  endYear: number
}

function AddEducationModal({
  qualification,
  onSubmit,
  closeModal,
}: AddEducationModalProps) {
  const masterData = useContext(MasterDataContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [startMonth, setStartMonth] = useState('')
  const [endMonth, setEndMonth] = useState('')
  const [err, setErr] = useState({ status: false, text: '' })
  const initialVal: QualificationInput = useMemo(() => {
    if (qualification) {
      setStartMonth(months[new Date(qualification.start_date).getMonth()])
      setEndMonth(months[new Date(qualification.end_date).getMonth()])
      return {
        qualification: qualification.qualification,
        institution: qualification.institution,
        field_of_study: qualification.field_of_study,
        startMonth: months[new Date(qualification.start_date).getMonth()],
        startYear:
          new Date(qualification.start_date).getFullYear() === 1970
            ? 2000
            : new Date(qualification.start_date).getFullYear(),
        endMonth: months[new Date(qualification.end_date).getMonth()],
        endYear:
          new Date(qualification.end_date).getFullYear() === 1970
            ? 2000
            : new Date(qualification.end_date).getFullYear(),
      }
    }
    return {
      qualification: '',
      institution: '',
      field_of_study: '',
      startMonth: '',
      startYear: 0,
      endMonth: '',
      endYear: 0,
    }
  }, [qualification])

  const validationSchema = Yup.object().shape({
    qualification: Yup.string().required('Please enter the qualification.'),
    institution: Yup.string().required('Please enter the institution.'),
    field_of_study: Yup.string().required('Please enter the field of study.'),
    startMonth: Yup.string().required('Please enter the start month.'),
    startYear: Yup.number()
      .min(1921, 'Please enter the start year.')
      .required('Please enter the start year.')
      .max(Yup.ref('endYear'), 'Start year should be less than end year.'),
    endMonth: Yup.string().required('Please enter the end month.'),
    endYear: Yup.number()
      .max(2119, 'Please enter the end year.')
      .required('Please enter the end year.')
      .min(Yup.ref('startYear'), 'End year should be greater than start year.'),
  })

  const [suggestions, setSuggestions] = useState<any[]>([])
  useEffect(() => {
    if (masterData?.masterData) {
      const valuesToRemove = [
        'Cert IV',
        'Bachelor',
        'Bachelors',
        'Bachelors Degree',
        'Master',
        'Masters',
        'Master Degree',
      ]
      let allDataSet = masterData.masterData
        .filter((fd: any) => fd.category === 'qualification')
        .sort((a: any, b: any) => a.order - b.order)
      allDataSet = allDataSet.filter(
        (item: any) => !valuesToRemove.includes(item.value)
      )
      setSuggestions(allDataSet)
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  function handleSubmit(values: QualificationInput) {
    onSubmit({
      id: '',
      qualification: values.qualification,
      institution: values.institution,
      field_of_study: values.field_of_study,
      // Use the 10th to avoid weird timezone tricks where 1st month can become 31st previous month.
      start_date: new Date('10 ' + values.startMonth + ' ' + values.startYear),
      end_date: new Date('10 ' + values.endMonth + ' ' + values.endYear),
    })
    closeModal()
  }

  useEffect(() => {
    if (startMonth !== '' && endMonth !== '') {
      const startMonthIndex = months.indexOf(startMonth)
      const endMonthIndex = months.indexOf(endMonth)

      endMonthIndex < startMonthIndex
        ? setErr({
            status: true,
            text: 'End date should be greater than start date',
          })
        : setErr({ status: false, text: '' })
    } else {
      setErr({ status: false, text: '' })
    }
  }, [startMonth, endMonth])

  return (
    <>
      <Formik
        initialValues={initialVal}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className={styles.modal}>
              <div className={'mt-4'}>
                <CustomSearch
                  name={'institution'}
                  placeholder={'School / Institute *'}
                  defaultValue={values.institution}
                  onChange={(value: string) =>
                    setFieldValue('institution', value)
                  }
                  endPoint={(input: string) =>
                    'data/universities?' + new URLSearchParams({ input: input })
                  }
                  mapResults={(res: any) => res.result.map((a: any) => a.name)}
                  minChars={3}
                  timeout={1000}
                />
                {errors.institution && touched.institution && (
                  <div className={styles.error}>{errors.institution}</div>
                )}
              </div>
              <div className={'mt-3'}>
                <CustomDropdown
                  name={'qualification'}
                  placeHolder={'Level of qualification *'}
                  dataList={suggestions.map((value) => ({
                    text: value.value,
                    value: value.id,
                  }))}
                  searchedValue={values.qualification}
                  selectedValue={
                    suggestions
                      .filter(
                        (fd: any) =>
                          fd.id === values.qualification.toLowerCase()
                      )
                      .map((md: any) => ({
                        text: md.value,
                        value: md.id,
                      }))[0]
                  }
                  getSelectedItem={(item) => {
                    setFieldValue('qualification', item.value)
                  }}
                />
                {errors.qualification && touched.qualification && (
                  <div className={styles.error}>{errors.qualification}</div>
                )}
              </div>
              <CustomInputField
                name={'field_of_study'}
                placeholder={'Field of Study *'}
                className={'mt-3'}
                onChange={(event: any) =>
                  setFieldValue('field_of_study', event.target.value)
                }
              />
              <div className={` mt-3 ${styles.borderBottomLight}`}>
                <h6 className={'text-almostBlack text-normal mt-3'}>
                  Start Date
                </h6>
                <div className={styles.monthYear}>
                  <div className={styles.monthDropdownModal}>
                    <CustomDropdown
                      dropUp
                      dataList={listMonths}
                      placeHolder={'Month *'}
                      selectedValue={
                        listMonths.filter(
                          (m) => m.value === values.startMonth
                        )[0]
                      }
                      getSelectedItem={(item: any) => {
                        setFieldValue('startMonth', item.value)
                        setStartMonth(item.value)
                      }}
                    />
                    {errors.startMonth && touched.startMonth && (
                      <div className={styles.error}>{errors.startMonth}</div>
                    )}
                  </div>
                  <div className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}>
                    <YearPicker
                      label={'Year *'}
                      maxDate={
                        new Date(
                          values.endYear > 1900 ? values.endYear : 2100,
                          0,
                          1
                        )
                      }
                      SelectYear={
                        values.startYear
                          ? new Date(values.startYear, 0, 1)
                          : undefined
                      }
                      getSelectedYear={(data) => {
                        setFieldValue('startYear', moment(data).format('YYYY'))
                        setFieldValue('endYear', 0)
                        setFieldValue('endMonth', '')
                      }}
                    />
                    {errors.startYear && touched.startYear && (
                      <div className={styles.error}>{errors.startYear}</div>
                    )}
                  </div>
                </div>
                <h6 className={'text-almostBlack text-normal mt-3'}>
                  End Date
                </h6>
                <div className={styles.monthYear}>
                  <div className={styles.monthDropdownModal}>
                    <CustomDropdown
                      dropUp
                      dataList={listMonths}
                      placeHolder={'Month *'}
                      selectedValue={
                        listMonths.filter((m) => m.value === values.endMonth)[0]
                      }
                      getSelectedItem={(item: any) => {
                        setFieldValue('endMonth', item.value)
                        setEndMonth(item.value)
                      }}
                    />
                    {errors.endMonth && touched.endMonth && (
                      <div className={styles.error}>{errors.endMonth}</div>
                    )}
                    {err.status && (
                      <div className={styles.error}>{err.text}</div>
                    )}
                  </div>

                  <div className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}>
                    <YearPicker
                      label={'Year *'}
                      minDate={
                        new Date(
                          values.startYear > 1900 ? values.startYear : 1900,
                          0,
                          1
                        )
                      }
                      SelectYear={
                        values.endYear
                          ? new Date(values.endYear, 0, 1)
                          : undefined
                      }
                      getSelectedYear={(data) => {
                        setFieldValue('endYear', moment(data).format('YYYY'))
                      }}
                    />
                    {errors.endYear && touched.endYear && (
                      <div className={styles.error}>{errors.endYear}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-5'}>
                <CustomButton
                  type={'button'}
                  className={styles.whiteBtn}
                  icon={<RecycleBinIcon />}
                  iconSide={'left'}
                  text={'Discard'}
                  onClick={closeModal}
                />
                {qualification === null ? (
                  <CustomButton
                    type={'submit'}
                    text={'Add Education'}
                    className={styles.modalBtn}
                    disabled={err.status}
                  />
                ) : (
                  <CustomButton
                    type={'submit'}
                    text={'Edit Education'}
                    className={styles.modalBtn}
                    disabled={values === initialVal || err.status}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AddEducationModal
