import React, { HTMLProps, ReactNode, useCallback, useState } from 'react'
import styles from './CustomInputField.module.scss'
import { FieldHookConfig, useField } from 'formik'
import EyeIcon from '../../../icons/eye.icon'
import HideEyeIcon from '../../../icons/hideEye.icon'

interface InputProps extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode
  pwError?: boolean
}

const InputField = (props: FieldHookConfig<string> & InputProps) => {
  const [field, meta] = useField(props)
  const [inputType, setInputType] = useState(props.type)
  const [pwError] = useState(props.pwError ?? false)
  const handleChangeFieldType = useCallback(() => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }, [inputType])

  const handleChange = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <div className={'w-100'}>
      <div className={`${styles.formField} ${props.className ?? ''} `}>
        <input
          {...field}
          id={props.id || props.name}
          className={` ${props.icon ? styles.iconCustomField : styles.customField} ${props.className ?? ''} ${props.is === 'large' ? 'form-control-lg' : ''} ${props.disabled ? styles.disabled : ''}`}
          type={inputType}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={
            props.defaultValue === '' || props.value === ''
              ? ''
              : props.defaultValue
                ? props.defaultValue
                : field.value
          }
          onChange={handleChange}
        />
        {props.icon && <div className={styles.icon}>{props.icon}</div>}
        {props.placeholder && (
          <label
            htmlFor={props.id || props.name}
            className={`form-label ${styles.formLabel}`}
          >
            {props.placeholder}
          </label>
        )}
        {props.type === 'password' && (
          <button
            type="button"
            className={`btn ${styles.showHidePassword}`}
            onClick={handleChangeFieldType}
          >
            {inputType === 'password' ? <HideEyeIcon /> : <EyeIcon />}
          </button>
        )}
      </div>
      {!pwError && meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default InputField
