import { useContext } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import { Form, Formik } from 'formik'
import CustomButton from '../shared/CustomButton/customButton'
import PlusIcon from '../../icons/plus.icon'
import DegreeIcon from '../../icons/degree.icon'
import EditIcon from '../../icons/edit.icon'
import CalenderIcon from '../../icons/calender.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CertificateIcon from '../../icons/certificate.icon'
import CustomLayout from '../layout/commonLayout'
import CustomModal from '../shared/customModal/customModal'
import CustomInputField from '../shared/customInputField/CustomInputField'
import * as Yup from 'yup'
import YearPicker from '../shared/calender/YearPicker'
import moment from 'dayjs'
import { Button } from 'react-bootstrap'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import { Auth } from 'aws-amplify'
import LoadingService from '../../services/loading/loading.service'
import { CandidateService } from '../../services/candidate/candidate.service'
import DataService from '../../services/data/data.service'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import isEqual from 'lodash/isEqual'
import { UserContext } from '../../context'
import { MasterDataContext } from '../../context/masterData'
import dayjs from 'dayjs'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { SignUpFormFooter } from '../signUpFormFooter/signUpFormFooter'

const month = [
  { value: '1', text: 'January' },
  { value: '2', text: 'February' },
  { value: '3', text: 'March' },
  {
    value: '4',
    text: 'April',
  },
  { value: '5', text: 'May' },
  { value: '6', text: 'June' },
  { value: '7', text: 'July' },
  {
    value: '8',
    text: 'August',
  },
  { value: '9', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' },
]

const Qualification = () => {
  const userData = useContext(UserContext)
  const masterData = useContext(MasterDataContext)
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const [modalEducation, setModalEducation] = useState(false)
  const [educationData, setEducationData] = useState<any>([])
  const [certificateData, setOtherCertificateData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isRefetch, setIsRefetch] = useState(false)
  const [isError, setIsError] = useState(false)
  const [startYear, setStartYear] = useState(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endYear, setEndYear] = useState(new Date())
  const [year, setYear] = useState(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editFlag, setEditFlag] = useState(false)
  const [editCertificateFlag, setEditCertificateFlag] = useState(false)
  const [certificateList, setCertificateList] = useState<any[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [startDate, setstartDate] = useState('')
  const [endDate, setendDate] = useState('')
  const [err, setErr] = useState({ status: false, text: '' })
  const valuesToRemove = [
    'Cert IV',
    'Bachelor',
    'Bachelors',
    'Bachelors Degree',
    'Master',
    'Masters',
    'Master Degree',
  ]
  const formattedDate = new Date(
    moment(new Date()).format('YYYY-MM-DD')
  ).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
  })
  const [qualificationLevels, setQualificationLevels] = useState<any[]>([])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const dataService: DataService = useMemo(() => {
    return new DataService()
  }, [])

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])

  useEffect(() => {
    //scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    loadingService
      .await(candidateService.updateLastPage('qualification'))
      .then()
  }, [loadingService, candidateService, userData])

  useEffect(() => {
    if (masterData?.masterData) {
      setCertificateList(
        masterData.masterData
          .filter((fd: any) => fd.category === 'licences_and_certifications')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => ({ value: item.id, text: item.value }))
      )
    } else {
      if (masterData.isError) {
        setIsError(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      endDate <= startDate
        ? setErr({
            status: true,
            text: 'End date should be greater than start date',
          })
        : setErr({ status: false, text: '' })
    } else {
      setErr({ status: false, text: '' })
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (masterData?.masterData) {
      setQualificationLevels(
        masterData.masterData
          .filter((fd: any) => fd.category === 'qualification')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setIsError(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  const [organizationCertificate, setOrganizationCertificate] = useState('')
  const [organizationEducation, setOrganizationEducation] = useState('')
  const [starMonth, setStartMonth] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [eduStarMonth, setEduStartMonth] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endMonth, setEndMonth] = useState('')
  const [reload, setReload] = useState(false)
  const [organizationList, setOrganizationList] = useState<
    { value: string; text: string }[]
  >([])
  const [initialEducationVal, setInitialEducationVal] = useState({
    id: '',
    school: '',
    degree: '',
    field: '',
    startYear: new Date(moment(new Date()).format('YYYY-MM-DD')).toLocaleString(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
      }
    ),
    endYear: new Date(moment(new Date()).format('YYYY-MM-DD')).toLocaleString(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
      }
    ),
    startMonth: '',
    endMonth: '',
  })

  const [initialCertificateVal, setInitialCertificateVal] = useState({
    id: '',
    name: '',
    issuingOrganisation: '',
    referenceNumber: '',
    year: moment(new Date()).format('YYYY-MM-DD'),
    month: moment(new Date()).format('YYYY-MM-DD'),
  })

  const validationSchema = Yup.object().shape({
    school: Yup.string().required('School is required.'),
    degree: Yup.string().required('Degree is required.'),
    field: Yup.string().required('Field is required.'),
  })

  const validationSchemaCertificate = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    issuingOrganisation: Yup.string()
      .nullable()
      .typeError('Issuing Organisation is required')
      .required('Issuing organisation is required.'),
    referenceNumber: Yup.string(),
  })

  const closeEducationModel = (val: any) => {
    setInitialEducationVal({
      id: '',
      school: '',
      degree: '',
      field: '',
      startYear: new Date(
        moment(new Date()).format('YYYY-MM-DD')
      ).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
      }),
      endYear: new Date(moment(new Date()).format('YYYY-MM-DD')).toLocaleString(
        'en-US',
        {
          year: 'numeric',
          month: 'short',
        }
      ),
      startMonth: '',
      endMonth: '',
    })
    setModalEducation(val)
    setstartDate('')
    setendDate('')
    setErr({ status: false, text: '' })
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    setSubmitting(true)
    try {
      setIsLoading(true)
      if (values.id) {
        const config: any = {
          id: values.id,
          qualification: values.degree,
          institution: values.school,
          field_of_study: values.field,
          start_date: `${moment(new Date(values.startYear)).format('YYYY')}-${moment(new Date(values.startMonth)).format('MM')}-01`,
          end_date: `${moment(new Date(values.endYear)).format('YYYY')}-${moment(new Date(values.endMonth)).format('MM')}-01`,
        }
        if (config.start_date.includes('Invalid')) {
          delete config.start_date
        }
        if (config.end_date.includes('Invalid')) {
          delete config.end_date
        }
        await loadingService.await(candidateService.updateQualification(config))
        const resultEducation = await loadingService.await(
          candidateService.getQualifications()
        )
        setEducationData(resultEducation?.result)
        setReload(true)
        closeEducationModel(false)
        setIsRefetch(!isRefetch)
        setIsLoading(false)
        setOrganizationEducation('')
        setEduStartMonth('')
        setEndMonth('')
      } else {
        const config: any = {
          qualification: values.degree,
          institution: values.school,
          field_of_study: values.field,
          start_date: `${moment(new Date(values.startYear)).format('YYYY')}-${moment(new Date(values.startMonth)).format('MM')}-01`,
          end_date: `${moment(new Date(values.endYear)).format('YYYY')}-${moment(new Date(values.endMonth)).format('MM')}-01`,
        }
        if (config.start_date.includes('Invalid')) {
          delete config.start_date
        }
        if (config.end_date.includes('Invalid')) {
          delete config.end_date
        }
        await loadingService.await(candidateService.addQualifications([config]))
        loadingService
          .await(candidateService.getQualifications())
          .then((res) => {
            if (res && res.status === 'success') {
              if (masterData && masterData.masterData) {
                const rank = masterData.masterData
                  .filter((a: any) => a?.category?.toString()?.endsWith('rank'))
                  .map((d: any) => {
                    return {
                      ...d,
                      qualification: d.category.split('_')[0],
                    }
                  })
                /**
                 * Array of qualifications data with start_date and end_date properties converted to Date objects.
                 * @type {Array<Qualification>}
                 */
                const qualificationsData: Array<Object> = res.result.map(
                  (q: any) => ({
                    ...q,
                    start_date: new Date(q.start_date),
                    end_date: new Date(q.end_date),
                  })
                )

                qualificationsData.map((q: any) => {
                  q.sortValue =
                    rank.filter(
                      (fd: any) => fd.qualification === q.qualification
                    )[0]?.value ?? 0
                })

                qualificationsData.sort(
                  (a: any, b: any) => b.sortValue - a.sortValue
                )

                qualificationsData.forEach((q: any) => {
                  q.certification =
                    masterData.masterData
                      .filter(
                        (fd: any) =>
                          fd.category === 'licences_and_certifications'
                      )
                      .filter((fd: any) => fd.id === q.certification)[0]
                      ?.value ?? q.certification
                })
                setEducationData(qualificationsData)
              }
            }
          })
        closeEducationModel(false)
        setIsLoading(false)
        setOrganizationEducation('')
        setEndMonth('')
        setEduStartMonth('')
      }

      setSubmitting(false)
      resetForm(true)
    } catch (error: any) {
      closeEducationModel(false)
      setIsLoading(false)
      setSubmitting(false)
      setOrganizationEducation('')
      setIsError(true)
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        loadingService
          .await(candidateService.getQualifications())
          .then((res) => {
            if (res && res.status === 'success') {
              if (masterData && masterData.masterData) {
                const rank = masterData.masterData
                  .filter((a: any) => a?.category?.toString()?.endsWith('rank'))
                  .map((d: any) => {
                    return {
                      ...d,
                      qualification: d.category.split('_')[0],
                    }
                  })
                /**
                 * Array of qualifications data with start_date and end_date properties converted to Date objects.
                 * @type {Array<Qualification>}
                 */
                const qualificationsData: Array<Object> = res.result.map(
                  (q: any) => ({
                    ...q,
                    start_date: new Date(q.start_date),
                    end_date: new Date(q.end_date),
                  })
                )

                qualificationsData.map((q: any) => {
                  q.sortValue =
                    rank.filter(
                      (fd: any) => fd.qualification === q.qualification
                    )[0]?.value ?? 0
                })

                qualificationsData.sort(
                  (a: any, b: any) => b.sortValue - a.sortValue
                )

                qualificationsData.forEach((q: any) => {
                  q.certification =
                    masterData.masterData
                      .filter(
                        (fd: any) =>
                          fd.category === 'licences_and_certifications'
                      )
                      .filter((fd: any) => fd.id === q.certification)[0]
                      ?.value ?? q.certification
                })
                setEducationData(qualificationsData)
                setReload(false)
              }
            }
          })
        const resultCertificate = await loadingService.await(
          candidateService.getCertifications()
        )
        setOtherCertificateData(resultCertificate?.result)
        setReload(false)
      } catch (error: any) {
        setIsError(true)
        setIsLoading(false)
        setReload(false)
        setErrorMessage(error.message)
      }
      setIsLoading(false)
      setReload(false)
    }

    fetchData().then()
  }, [
    candidateService,
    isRefetch,
    loadingService,
    setIsError,
    masterData,
    reload,
  ])

  const deleteEducation = async (id: any) => {
    try {
      setIsLoading(true)
      await loadingService.await(
        candidateService.removeQualifications([{ id }])
      )
      setReload(true)
      setIsLoading(false)
    } catch (error: any) {
      setIsError(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  const deleteOtherCertificate = async (id: any) => {
    try {
      setIsLoading(true)
      await loadingService.await(
        candidateService.removeCertifications([{ id }])
      )
      const resultCertificate = await loadingService.await(
        candidateService.getCertifications()
      )
      setOtherCertificateData(resultCertificate?.result)
      setIsLoading(false)
    } catch (error: any) {
      setIsError(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  const closeEducationModel2 = (val: any) => {
    setInitialEducationVal({
      id: '',
      school: '',
      degree: '',
      field: '',
      startYear: new Date(
        moment(new Date()).format('YYYY-MM-DD')
      ).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
      }),
      endYear: new Date(moment(new Date()).format('YYYY-MM-DD')).toLocaleString(
        'en-US',
        {
          year: 'numeric',
          month: 'short',
        }
      ),
      startMonth: '',
      endMonth: '',
    })
    setModalEducation(val)
    setstartDate('')
    setendDate('')
    setErr({ status: false, text: '' })
  }

  const closeCertificateModel = (val: any) => {
    setInitialCertificateVal({
      id: '',
      name: '',
      issuingOrganisation: '',
      referenceNumber: '',
      year: moment(new Date()).format('YYYY-MM-DD'),
      month: moment(new Date()).format('YYYY-MM-DD'),
    })
    setModal(val)
  }

  const handleSubmitCertificate = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    setSubmitting(true)
    setIsLoading(true)
    try {
      if (values.id) {
        const config = {
          id: values.id,
          certification: values.name,
          organization: values.issuingOrganisation,
          reference_id: values.referenceNumber,
          issue_date: `${moment(new Date(values.year)).format('YYYY')}-${moment(new Date(values.month)).format('MM')}-01`,
        }
        await loadingService.await(candidateService.updateCertification(config))
        const resultCertificate: any = await loadingService.await(
          candidateService.getCertifications()
        )
        setOtherCertificateData(resultCertificate?.result)
        setSubmitting(false)
        resetForm(true)
        closeCertificateModel(false)
        setIsRefetch(!isRefetch)
        setIsLoading(false)
        setOrganizationCertificate('')
        setStartMonth('')
      } else {
        await loadingService.await(
          candidateService.addCertifications([
            {
              certification: values.name,
              organization: values.issuingOrganisation,
              reference_id: values.referenceNumber,
              issue_date: `${moment(new Date(values.year)).format('YYYY')}-${moment(new Date(values.month)).format('MM')}-01`,
            },
          ])
        )
        const resultCertificate: any = await loadingService.await(
          candidateService.getCertifications()
        )
        setSubmitting(false)
        resetForm(true)
        setOtherCertificateData(resultCertificate?.result)
        closeCertificateModel(false)
        setIsLoading(false)
        setOrganizationCertificate('')
        setStartMonth('')
      }
    } catch (error: any) {
      closeCertificateModel(false)
      setIsLoading(false)
      setSubmitting(false)
      setOrganizationCertificate('')
      setIsError(true)
      setErrorMessage(error.message)
    }
  }

  const handleBack = function () {
    navigate('/resumeImport')
  }

  const handleSaveAndExit = async function () {
    localStorage.removeItem('masterData')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('login_form')
    await loadingService.await(Auth.signOut())
    navigate('/signIn?type=candidateSignIn')
  }

  const handleGeOrg = useCallback(
    async (event: string) => {
      const inputData = event
      try {
        if (inputData === '') {
          setOrganizationList([])
        }
        const result = await dataService.getUniversities(inputData)
        if (result === null) {
          setOrganizationList([])
          return
        }
        try {
          setOrganizationList(
            result.result
              .sort((a: any, b: any) => a.order - b.order)
              .map((value: { name: string }) => {
                return {
                  value: value.name,
                  text: value.name,
                }
              })
          )
        } catch (error: any) {
          setIsError(true)
          setErrorMessage(error.message)
        }
      } catch (error: any) {
        setIsError(true)
        setErrorMessage(error.message)
      }
    },
    [dataService]
  )

  const handleContinue = async () => {
    if (Object.keys(userData.candidateData).length !== 0) {
      const cData = userData.candidateData
      let paged: any
      try {
        paged = JSON.parse(cData.profile_pages)
      } catch (e) {
        paged = cData.profile_pages
      }
      const updatePage = JSON.stringify({
        ...paged,
        ...{ qualification: 'complete' },
      })
      const updatePages = {
        ...cData,
        profile_pages: updatePage,
      }
      userData.setCandidateData(updatePages)
    }
    await loadingService.await(
      candidateService.updateProfilePages(
        'qualification',
        'complete',
        userData.candidateData
      )
    )
    navigate('/experience')
  }

  /**
   * Checks if a given string is a valid UUID.
   * @param uuid - The string to be checked.
   * @returns A boolean indicating whether the string is a valid UUID or not.
   */
  const isValidUUID = (uuid: string) => {
    const uuidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return uuidPattern.test(uuid)
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout
          title={'Qualifications'}
          subTitle={'Tell us about your education qualifications'}
          pageName={'qualification'}
          progressValue={40}
        >
          {isLoading && <LoadingSpinner />}
          <div className={styles.outerContent}>
            <h6 className={'text-bold gray-color-text'}>
              Australian Education
            </h6>
            <div>
              <div className={styles.innerContent}>
                <CustomButton
                  type={'button'}
                  text={'Add Education'}
                  icon={<PlusIcon />}
                  iconSide={'left'}
                  variant={'transparent'}
                  className={styles.fontColor}
                  onClick={() => {
                    setModalEducation(!modalEducation)
                    setEditFlag(false)
                  }}
                />
                {educationData.map((educationdata: any) => (
                  <div key={educationdata.id} className={styles.educationCard}>
                    <div
                      className={
                        'd-flex justify-content-between align-items-center'
                      }
                    >
                      <DegreeIcon />
                      <Button
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => {
                          setModalEducation(true)
                          setInitialEducationVal({
                            id: `${educationdata.id}`,
                            school: `${educationdata.institution}`,
                            degree: `${educationdata.qualification}`,
                            field: `${educationdata.field_of_study}`,
                            startYear: new Date(
                              moment(educationdata.start_date).format(
                                'YYYY-MM-DD'
                              )
                            ).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                            }),
                            endYear: new Date(
                              moment(educationdata.end_date).format(
                                'YYYY-MM-DD'
                              )
                            ).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                            }),
                            startMonth: `${new Date(educationdata.start_date).getMonth() + 1}`,
                            endMonth: `${new Date(educationdata.end_date).getMonth() + 1}`,
                          })
                          setOrganizationEducation(educationdata.institution)
                          setEduStartMonth(
                            `${educationdata.start_date}`.substring(5, 7)
                          )
                          setStartYear(educationdata.start_date)
                          setEndMonth(
                            `${educationdata.end_date}`.substring(5, 7)
                          )
                          setstartDate(
                            `${new Date(educationdata.start_date).getFullYear()} ${new Date(educationdata.start_date).getMonth() + 1}`
                          )
                          setendDate(
                            `${new Date(educationdata.end_date).getFullYear() === 1970 ? new Date('Jan 2001') : new Date(educationdata.end_date)} ${new Date(educationdata.end_date).getMonth() + 1}`
                          )
                          setEditFlag(true)
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </div>
                    <div>
                      <h6 className={'text-bold gray-color-text'}>
                        {qualificationLevels?.filter(
                          (fd: any) =>
                            fd.id === educationdata?.qualification.toLowerCase()
                        )[0]?.value ?? educationdata?.qualification}
                      </h6>
                      <h6 className={'text-normal text gray-color-text'}>
                        {educationdata.field_of_study}
                      </h6>
                      <h6 className={'text-normal text  gray-color-text'}>
                        {educationdata.institution}
                      </h6>
                      <div className={'d-flex align-items-center'}>
                        {new Date(educationdata.start_date).getFullYear() !==
                          1970 ||
                        new Date(educationdata.end_date).getFullYear() !==
                          1970 ? (
                          <>
                            <CalenderIcon />
                            <div
                              className={
                                'd-flex justify-content-between align-items-center w-100'
                              }
                            >
                              <h6 className="ms-3 text-gray-light text-normal">
                                {new Date(
                                  educationdata.start_date
                                ).getFullYear() === 1970
                                  ? dayjs(educationdata.end_date).format(
                                      'MMM YYYY'
                                    )
                                  : new Date(
                                        educationdata.end_date
                                      ).getFullYear() === 1970
                                    ? dayjs(educationdata.start_date).format(
                                        'MMM YYYY'
                                      )
                                    : `${dayjs(educationdata.start_date).format('MMM YYYY')} - ${dayjs(educationdata.end_date).format('MMM YYYY')}`}
                              </h6>
                              <Button
                                style={{ background: 'none', border: 'none' }}
                                onClick={() =>
                                  deleteEducation(educationdata.id)
                                }
                              >
                                <RecycleBinIcon />
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div
                            className={
                              'd-flex justify-content-between align-items-center w-100'
                            }
                          >
                            <p></p>
                            <Button
                              style={{ background: 'none', border: 'none' }}
                              onClick={() => deleteEducation(educationdata.id)}
                            >
                              <RecycleBinIcon />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`mt-5 ${styles.outerContent}`}>
            <h6 className={'text-bold gray-color-text'}>
              Certificates & Licences
            </h6>
            <div>
              <div className={styles.innerContent}>
                <CustomButton
                  type={'button'}
                  text={'Add Another'}
                  icon={<PlusIcon />}
                  iconSide={'left'}
                  variant={'transparent'}
                  className={styles.fontColor}
                  onClick={() => {
                    setEditCertificateFlag(false)
                    closeCertificateModel(!modal)
                  }}
                />

                {certificateData.map((certificateData: any) => (
                  <div
                    key={certificateData.id}
                    className={styles.educationCard}
                  >
                    <div
                      className={
                        'd-flex justify-content-between align-items-center'
                      }
                    >
                      <CertificateIcon />
                      <Button
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => {
                          setModal(true)
                          setInitialCertificateVal({
                            id: `${certificateData.id}`,
                            name: `${certificateData.certification}`,
                            issuingOrganisation: `${certificateData.organization}`,
                            referenceNumber: `${certificateData.reference_id}`,
                            year: `${certificateData.issue_date ?? new Date()}`,
                            month: `${certificateData.issue_date ?? new Date()}`,
                          })
                          setOrganizationCertificate(
                            certificateData.organization
                          )
                          setStartMonth(
                            `${moment(new Date(certificateData.issue_date)).format('MMMM')}`
                          )
                          setYear(certificateData.issue_date ?? new Date())
                          setEditCertificateFlag(true)
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </div>
                    <div>
                      <h6 className={'text-bold gray-color-text'}>
                        {isValidUUID(certificateData.certification)
                          ? certificateList.filter(
                              (fd: any) =>
                                fd.value === certificateData.certification
                            )[0]?.text
                          : certificateData.certification}
                      </h6>
                      <h6 className={'text-normal text  gray-color-text'}>
                        {certificateData.organization}
                      </h6>

                      <div className={'d-flex  align-items-center'}>
                        {new Date(certificateData.issue_date).getFullYear() ===
                        1970 ? (
                          <></>
                        ) : (
                          <CalenderIcon />
                        )}
                        <div
                          className={
                            'd-flex justify-content-between align-items-center w-100'
                          }
                        >
                          <h6 className="ms-3 text-gray-light  text-normal">
                            {new Date(
                              certificateData.issue_date
                            ).getFullYear() === 1970
                              ? ''
                              : `${dayjs(certificateData.issue_date).format('MMM YYYY')} | `}
                            Ref/Member No{' '}
                            {certificateData.reference_id
                              ? certificateData.reference_id
                              : certificateData.reference_id === 'null'
                                ? '-'
                                : '-'}
                          </h6>
                          <Button
                            style={{ background: 'none', border: 'none' }}
                            onClick={() =>
                              deleteOtherCertificate(certificateData.id)
                            }
                          >
                            <RecycleBinIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {modal && (
            <CustomModal
              title={
                initialCertificateVal.id === ''
                  ? 'Add Certificate'
                  : 'Update Certificate'
              }
              open={modal}
              onCloseModal={(val) => {
                setModal(val)
                setOrganizationCertificate('')
                setStartMonth('')
              }}
            >
              <Formik
                initialValues={initialCertificateVal}
                onSubmit={handleSubmitCertificate}
                validationSchema={validationSchemaCertificate}
                enableReinitialize
              >
                {({ setFieldValue, values, errors }) => (
                  <Form className={styles.modal}>
                    <div>
                      <CustomInputField
                        name={'id'}
                        type="hidden"
                        onChange={(event: any) =>
                          setFieldValue('id', event.target.value)
                        }
                      />
                      <div className={'mt-3'}>
                        <CustomDropdown
                          name={'name'}
                          placeHolder={'Name *'}
                          dataList={certificateList}
                          onChange={(event) => {
                            setFieldValue('name', event)
                          }}
                          getSelectedItem={(select) => {
                            setFieldValue('name', select.value)
                          }}
                          selectedValue={
                            certificateList.filter((i) => {
                              return i.value === values.name
                            })[0]
                          }
                        />
                        {errors.name && (
                          <div
                            className={styles.yupError}
                            style={{
                              marginTop: '-10px',
                              marginBottom: '25px',
                              position: 'relative',
                            }}
                          >
                            {errors.name}
                          </div>
                        )}
                      </div>
                      <div className={'mt-3'}>
                        <CustomDropdown
                          name={`issuingOrganisation`}
                          selectedValue={{
                            text: organizationCertificate,
                            value: organizationCertificate,
                          }}
                          placeHolder={'Issuing Organisation *'}
                          dataList={organizationList}
                          onChange={(event) => {
                            setOrganizationCertificate(event)
                            handleGeOrg(event)
                          }}
                          getSelectedItem={(select) => {
                            setOrganizationCertificate(select.value)
                            setFieldValue(
                              'issuingOrganisation',
                              select.value ?? ''
                            )
                          }}
                        />
                        {errors.issuingOrganisation && (
                          <div
                            className={styles.yupError}
                            style={{
                              marginTop: '-10px',
                              marginBottom: '25px',
                              position: 'relative',
                            }}
                          >
                            {errors.issuingOrganisation}
                          </div>
                        )}
                      </div>
                      <CustomInputField
                        name={'referenceNumber'}
                        placeholder={'Reference/Member number'}
                        className={'mt-3'}
                        onChange={(event: any) =>
                          setFieldValue('referenceNumber', event.target.value)
                        }
                      />
                      <h6 className={'text-almostBlack text-normal mt-3'}>
                        Issuing Date
                      </h6>
                      <div
                        className={`d-md-flex mt-3 ${styles.borderBottomLight}`}
                      >
                        <div className={styles.monthDropdownModal}>
                          <CustomDropdown
                            dropUp
                            name={'month'}
                            dataList={month}
                            placeHolder={'Month *'}
                            selectedValue={{
                              text: starMonth,
                              value: starMonth,
                            }}
                            onChange={(event) => {
                              setStartMonth(event)
                            }}
                            getSelectedItem={(select) => {
                              setStartMonth(select.text)
                              setFieldValue('month', select.value)
                            }}
                          />
                        </div>
                        <div
                          className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}
                        >
                          {editCertificateFlag ? (
                            <YearPicker
                              label={'Year *'}
                              SelectYear={
                                new Date(year).getFullYear() === 1970
                                  ? new Date('2000')
                                  : new Date(year)
                              }
                              getSelectedYear={(data) => {
                                setYear(data)
                                setFieldValue(
                                  'year',
                                  moment(data).format('YYYY-MM-DD')
                                )
                              }}
                            />
                          ) : (
                            <YearPicker
                              label={'Year *'}
                              getSelectedYear={(data) => {
                                setYear(data)
                                setFieldValue(
                                  'year',
                                  moment(data).format('YYYY-MM-DD')
                                )
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className={'d-flex justify-content-between mt-5'}>
                        <CustomButton
                          type={'button'}
                          className={styles.whiteBtn}
                          icon={<RecycleBinIcon />}
                          iconSide={'left'}
                          text={'Discard'}
                          onClick={() => setModal(false)}
                          disabled={isLoading}
                        />
                        <CustomButton
                          loading={isLoading}
                          type={'submit'}
                          text={
                            values.id === ''
                              ? 'Add Certificate'
                              : 'Update Certificate'
                          }
                          className={styles.modalBtn}
                          disabled={
                            isLoading || isEqual(values, initialCertificateVal)
                          }
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CustomModal>
          )}
          {modalEducation && (
            <CustomModal
              title={
                initialEducationVal.id === ''
                  ? 'Add Education'
                  : 'Update Education'
              }
              open={modalEducation}
              onCloseModal={(val) => {
                closeEducationModel2(val)
                setOrganizationEducation('')
                setEduStartMonth('')
                setEndMonth('')
              }}
            >
              <Formik
                initialValues={initialEducationVal}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ setFieldValue, values, errors }) => (
                  <Form className={styles.modal}>
                    <div>
                      <CustomInputField
                        name={'id'}
                        type="hidden"
                        onChange={(event: any) =>
                          setFieldValue('id', event.target.value)
                        }
                      />
                      <CustomDropdown
                        name={`school`}
                        selectedValue={{
                          text: organizationEducation,
                          value: organizationEducation,
                        }}
                        dataList={organizationList}
                        placeHolder={'School / Institute *'}
                        onChange={(event) => {
                          setOrganizationEducation(event)
                          handleGeOrg(event)
                        }}
                        getSelectedItem={(select) => {
                          setOrganizationEducation(select.value)
                          setFieldValue('school', select.value)
                        }}
                      />
                      {errors.school && (
                        <div
                          className={styles.yupError}
                          style={{
                            marginTop: '-10px',
                            marginBottom: '25px',
                            position: 'relative',
                          }}
                        >
                          {errors.school}
                        </div>
                      )}
                      <div className={'mt-3'}>
                        <CustomDropdown
                          name={'degree'}
                          placeHolder={'Level of qualification *'}
                          dataList={qualificationLevels
                            .filter(
                              (item: any) =>
                                !valuesToRemove.includes(item.value)
                            )
                            .sort((a: any, b: any) => a.order - b.order)
                            .map((value: any) => ({
                              text: value.value,
                              value: value.id,
                            }))}
                          searchedValue={values.degree}
                          selectedValue={
                            qualificationLevels
                              .filter(
                                (fd: any) =>
                                  fd.id === values.degree.toLowerCase()
                              )
                              .map((md: any) => ({
                                text: md.value,
                                value: md.id,
                              }))[0]
                          }
                          getSelectedItem={(item) =>
                            setFieldValue('degree', item.value)
                          }
                        />
                        {errors.degree && (
                          <div className={styles.error}>{errors.degree}</div>
                        )}
                      </div>
                      <CustomInputField
                        name={'field'}
                        placeholder={'Field of Study *'}
                        className={'mt-3'}
                        onChange={(event: any) =>
                          setFieldValue('field', event.target.value)
                        }
                      />
                      <div className={` mt-3 ${styles.borderBottomLight}`}>
                        <h6 className={'text-almostBlack text-normal mt-3'}>
                          Start Date
                        </h6>

                        <div className={`w-100 ${styles.monthYear} `}>
                          <div className={styles.monthDropdownModal}>
                            <CustomDropdown
                              dropUp
                              name={'startMonth'}
                              dataList={month}
                              placeHolder={'Month *'}
                              selectedValue={
                                values.startMonth !== ''
                                  ? {
                                      text: month.filter(
                                        (month, index, val) =>
                                          parseInt(val[index].value) ===
                                          parseInt(values.startMonth)
                                      )[0]?.text,
                                      value: values.startMonth,
                                    }
                                  : undefined
                              }
                              onChange={(event) => {
                                setEduStartMonth(event)
                              }}
                              getSelectedItem={(select) => {
                                setEduStartMonth(select.text)
                                setFieldValue('startMonth', select.value)
                                setstartDate(
                                  `${parseInt(values.startYear.split(' ')[1])} ${select.value}`
                                )
                              }}
                            />
                          </div>
                          <div
                            className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}
                          >
                            <YearPicker
                              label={'Year *'}
                              SelectYear={
                                values.startYear.split(' ')[1] === '1970'
                                  ? new Date('2000')
                                  : values.startYear !== formattedDate
                                    ? new Date(values.startYear.split(' ')[1])
                                    : undefined
                              }
                              getSelectedYear={(data) => {
                                setStartYear(data)
                                values.startYear =
                                  moment(data).format('MMM YYYY')
                                setstartDate(
                                  `${parseInt(values.startYear.split(' ')[1])} ${values.startMonth}`
                                )
                                setendDate('')
                                setFieldValue(
                                  'endYear',
                                  new Date(
                                    moment(new Date()).format('YYYY-MM-DD')
                                  ).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                  })
                                )
                                setFieldValue('endMonth', '')
                              }}
                            />
                          </div>
                        </div>
                        <h6 className={'text-almostBlack text-normal mt-3'}>
                          End Date
                        </h6>
                        <div className={` w-100 ${styles.monthYear}`}>
                          <div className={styles.monthDropdownModal}>
                            <CustomDropdown
                              dropUp
                              name={'endMonth'}
                              dataList={month}
                              placeHolder={'Month *'}
                              selectedValue={
                                values.endMonth !== ''
                                  ? {
                                      text: month.filter(
                                        (month, index, val) =>
                                          parseInt(val[index].value) ===
                                          parseInt(values.endMonth)
                                      )[0]?.text,
                                      value: values.endMonth,
                                    }
                                  : undefined
                              }
                              onChange={(event) => {
                                setEndMonth(event)
                              }}
                              getSelectedItem={(select) => {
                                setEndMonth(select.text)
                                setFieldValue('endMonth', select.value)
                                setendDate(
                                  `${parseInt(values.endYear.split(' ')[1])} ${select.value}`
                                )
                              }}
                            />
                          </div>
                          <div
                            className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}
                          >
                            <YearPicker
                              label={'Year *'}
                              minDate={new Date(values.startYear) ?? startYear}
                              SelectYear={
                                values.endYear.split(' ')[1] === '1970'
                                  ? new Date('Jan 2001')
                                  : values.endYear !== formattedDate
                                    ? new Date(values.endYear.split(' ')[1])
                                    : new Date(values.endYear.split(' ')[1])
                              }
                              getSelectedYear={(data) => {
                                setEndYear(data)
                                setFieldValue(
                                  'endYear',
                                  moment(data).format('MMM YYYY')
                                )
                                setendDate(
                                  `${parseInt(moment(data).format('MMM YYYY').split(' ')[1])} ${values.endMonth}`
                                )
                              }}
                            />
                          </div>
                        </div>
                        {err.status && (
                          <div className={styles.error}>{err.text}</div>
                        )}
                      </div>

                      <div className={'d-flex justify-content-between mt-5'}>
                        <CustomButton
                          type={'button'}
                          className={styles.whiteBtn}
                          icon={<RecycleBinIcon />}
                          iconSide={'left'}
                          text={'Discard'}
                          onClick={() => setModalEducation(false)}
                          disabled={isLoading}
                        />
                        <CustomButton
                          loading={isLoading}
                          type={'submit'}
                          text={
                            values.id === ''
                              ? 'Add Education'
                              : 'Update Education'
                          }
                          className={styles.modalBtn}
                          disabled={
                            err.status ||
                            isLoading ||
                            isEqual(values, initialEducationVal)
                          }
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CustomModal>
          )}
          <SignUpFormFooter
            isLoading={isLoading}
            disableSaveAndExit={isLoading}
            handleBackClicked={handleBack}
            handleContinueClicked={handleContinue}
            disableContinue={
              educationData.length === 0 && certificateData.length === 0
            }
            handleSaveAndExitClicked={handleSaveAndExit}
          />
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={isError}
        onClose={() => {
          setIsError(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Qualification
