import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from '../../icons/edit.icon'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LoadingService from '../../services/loading/loading.service'
import { useNavigate } from 'react-router-dom'
import { CandidateService } from '../../services/candidate/candidate.service'
import HeartIcon from '../../icons/heart.icon'
import { UserContext } from '../../context'

import { DropdownDataList } from '../shared/CustomDropdown/customDropdown'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type PersonalProfileProps = {
  pageIsLoading: boolean
  tags?: string[]
}

function PreferencesProfile({ pageIsLoading }: PersonalProfileProps) {
  const navigate = useNavigate()
  const userData = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [tags, setTags] = useState<string[]>([])
  const [roleList, setRoleList] = useState<DropdownDataList[]>([])
  const masterData = useContext(MasterDataContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])

  useEffect(() => {
    if (masterData?.masterData) {
      setRoleList(
        masterData.masterData
          .filter((fd: any) => fd.category === 'finance_jobs')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => ({ value: item.id, text: item.value }))
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  useEffect(() => {
    if (userData.candidateData) {
      const tags = []
      if (userData.candidateData?.hours_weekly)
        tags.push(userData.candidateData?.hours_weekly + ' hrs/week')
      if (userData.candidateData?.commitment)
        tags.push(userData.candidateData?.commitment)
      if (userData.candidateData?.work_type)
        tags.push(userData.candidateData?.work_type)
      if (
        userData.candidateData?.on_site_days &&
        userData.candidateData?.work_type === 'Hybrid'
      )
        tags.push(
          userData.candidateData.on_site_days >= 3
            ? `More than two days`
            : userData.candidateData?.on_site_days > 1
              ? `${userData.candidateData.on_site_days} days on-site`
              : `${userData.candidateData.on_site_days} day on-site`
        )
      if (userData.candidateData?.pay_hourly)
        tags.push('$' + userData.candidateData?.pay_hourly + '/hr')
      if (userData.candidateData?.pay_annual)
        tags.push('$' + userData.candidateData?.pay_annual + '/yr')
      // Make sure the notice period is not in days
      if (userData.candidateData?.notice_period_days) {
        const noticePeriodInWeeks = Math.ceil(
          userData.candidateData.notice_period_days / 7
        )
        tags.push(
          `Notice Period: ${noticePeriodInWeeks} week${noticePeriodInWeeks > 1 ? 's' : ''}`
        )
      }
      if (userData.candidateData?.notice_period_days === 0)
        tags.push('Available Immediately')
      if (userData.candidateData?.suburb)
        tags.push('Lives in ' + userData.candidateData?.suburb)
      if (
        userData.candidateData?.commute_minutes &&
        userData.candidateData?.work_type !== 'Off-Site'
      ) {
        if (parseInt(userData.candidateData.commute_minutes) >= 60) {
          /**
           * Calculates the number of hours it takes for the user to commute based on the number of minutes.
           * @param {number} userData.commute_minutes - The number of minutes it takes for the user to commute.
           * @returns {number} The number of hours it takes for the user to commute.
           */
          const hours = Math.floor(userData.candidateData.commute_minutes / 60)
          const minutes = userData.candidateData.commute_minutes % 60
          if (minutes === 0) tags.push('Commute for ' + `${hours} hour`)
          else {
            tags.push('Commute for ' + `${hours} hours ${minutes} minutes`)
          }
        } else {
          tags.push(
            'Commute for ' + `${userData.candidateData.commute_minutes} minutes`
          )
        }
      }
      if (userData.candidateData?.preferred_role)
        tags.push(
          'Preferred Role: ' +
            roleList?.filter(
              (fd) => fd.value === userData.candidateData?.preferred_role
            )[0]?.text
        )
      setTags(tags)
    }
  }, [candidateService, loadingService, userData, roleList])

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
      >
        <div className={'d-flex align-items-center'}>
          <HeartIcon />
          <h4 className={'text-almostBlack ms-3'}>Preferences</h4>
        </div>
        <div
          onClick={() => {
            if (!isLoading && !pageIsLoading)
              navigate('/gettingStart', { state: { from: 'profile' } })
          }}
          className={'pointer'}
        >
          <EditIcon />
        </div>
      </div>
      <div className={'d-flex align-items-center w-100 flex-wrap'}>
        {tags.map((tag: string) => (
          <div key={tag} className={`me-3 ${styles.payableTag}`}>
            {tag}
          </div>
        ))}
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default PreferencesProfile
