import React from 'react'

interface Props {
  size?: number
  color?: string
}

const LiveIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 19}
      height={size ? size - 5 : 14}
      fill="none"
      viewBox={'0 0 19 14'}
    >
      <path
        stroke={color ?? '#00AE46'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 7v.01m2.828-2.838a4 4 0 0 1 0 5.656m2.829-8.485a7.998 7.998 0 0 1 0 11.314m-8.49-2.83a4 4 0 0 1 0-5.655m-2.83 8.485a8 8 0 0 1 0-11.314"
      />
    </svg>
  )
}

export default LiveIcon
