import LeftArrow from '../../icons/leftArrow.icon'
import RightLongArrow from '../../icons/rightLongArrow.icon'
import SaveIcon from '../../icons/save.icon'
import CustomButton from '../shared/CustomButton/customButton'
import styles from './SignUpFormFooter.module.scss'

interface Props {
  hideGoBack?: boolean
  disableSaveAndExit: boolean
  disableContinue: boolean
  disableGoBack?: boolean
  isLoading: boolean
  handleBackClicked?: () => void
  handleContinueClicked?: () => void
  handleSaveAndExitClicked: () => void
}

export const SignUpFormFooter: React.FC<Props> = ({
  hideGoBack,
  disableSaveAndExit,
  disableContinue,
  disableGoBack,
  isLoading,
  handleBackClicked,
  handleContinueClicked,
  handleSaveAndExitClicked,
}) => {
  return (
    <div
      className={
        'container flex-column-reverse flex-md-row d-flex justify-content-between align-items-center mt-5 w-100'
      }
    >
      <div className={'d-flex align-items-center'}>
        {!hideGoBack && (
          <CustomButton
            disabled={disableGoBack}
            type={'button'}
            text={'Go Back'}
            icon={<LeftArrow />}
            iconSide={'left'}
            className={styles.whiteBtn}
            onClick={handleBackClicked}
          />
        )}
        <CustomButton
          disabled={disableSaveAndExit}
          type={'button'}
          text={'Save & Exit'}
          icon={<SaveIcon />}
          iconSide={'left'}
          className={`ms-3 ${styles.outlineBtn}`}
          onClick={handleSaveAndExitClicked}
        />
      </div>
      <div className={'d-flex align-items-center mb-3 mb-md-0'}>
        <CustomButton
          loading={isLoading}
          disabled={disableContinue}
          type={'submit'}
          text={'Continue'}
          icon={<RightLongArrow size={14} />}
          iconSide={'right'}
          onClick={handleContinueClicked}
        />
      </div>
    </div>
  )
}
