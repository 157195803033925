import React from 'react'

import styles from './tooltip.module.scss'
import InfoIcon from '../../../icons/info.icon'
import colors from '../../../styles/variables.module.scss'

type TooltipProps = {
  body: string
}

function Tooltip({ body }: TooltipProps) {
  return (
    <div className={styles.tooltip}>
      <InfoIcon color={colors.infoColor} />
      <span className={styles.tooltiptext}>{body}</span>
    </div>
  )
}

export default Tooltip
