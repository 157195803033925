import React from 'react'

interface Props {
  size?: number
  color?: string
}

const UploadIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 19} height={size ?? 19} fill="none">
      <path
        d="M1.5 14v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2m-13-8 5-5m0 0 5 5m-5-5v12"
        stroke={color ?? '#D1D5DB'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UploadIcon
