import { useEffect, useMemo, useState } from 'react'
import styles from './assessment.module.scss'
import colors from '../../styles/variables.module.scss'
import { useNavigate } from 'react-router-dom'
import SendIcon from '../../icons/send.icon'
import CustomButton from '../shared/CustomButton/customButton'
import LoadingService from '../../services/loading/loading.service'
import UserService from '../../services/user/user.service'
import { CandidateService } from '../../services/candidate/candidate.service'
import { GetAssessmentDetailsResponse } from '../../services/candidate/candidate.res.model'
import { GetUserRole } from '../../services/user/user.res.model'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const StartAssessmentModal = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [lastAttempt, setLastAttempt] = useState<Date | null>(null)
  const [coolDownMilliseconds, setCoolDownMilliseconds] = useState(0)
  const [admin, setAdmin] = useState(false)
  const [adminOverride, setAdminOverride] = useState(false)
  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])
  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    loadingService
      .await(candidateService.getAssessmentDetails())
      .then((data: GetAssessmentDetailsResponse | null) => {
        if (data === null) {
          return
        }
        setLastAttempt(new Date(data.lastAttempt))
        setCoolDownMilliseconds(Number(data.cooldownRequired))
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(userService.getUserRole())
      .then((role: GetUserRole | null) => {
        if (role !== null && role.role === 'admin') {
          setAdmin(true)
        }
      })
  }, [candidateService, userService, loadingService])

  function canSitAssessment() {
    return (
      lastAttempt === null ||
      new Date().getTime() - lastAttempt.getTime() > coolDownMilliseconds ||
      adminOverride
    )
  }

  return (
    <div className={styles.modal}>
      {canSitAssessment() && (
        <div>
          <div className={`row mt-4 ${styles.yellowBox}`}>
            <div className={'col-sm-4'}>
              <h4 className={'text-bolder text-almostBlack'}>
                Things you needs to know
              </h4>
              <p className={'gray-color-text mt-3'}>
                There are 90 questions in total – 85 multiple choice and 5
                free-text questions and are in random order.
              </p>
              <p className={'gray-color-text mt-3'}>
                The time it takes you to complete the assessment is important
                and will be recorded alongside the accuracy of your answers as
                both are important. There is a timer that will be viewable at
                all times so you can track your progress.
              </p>
            </div>
            <div className={'ms-0 ms-md-4 col-sm-7 mt-3 mt-md-0'}>
              <h5 className={'text-almostBlack'}>Instructions</h5>
              <p className={'gray-color-text mt-3'}>
                Please don’t be tempted to get someone else or AI to complete
                these answers for you. Could you cheat? Sure! But you’ll miss a
                great opportunity to assess your current knowledge and find the
                optimum role for you. By using only what you know, you’ll find a
                better match.
              </p>
              <p className={'gray-color-text mt-3'}>
                This assessment should take you between 50 and 90 minutes to
                complete. It’s best completed somewhere quiet and
                distraction-free. Bear in mind that looking up answers online
                will slow you down.
              </p>
              <p className={'gray-color-text mt-3'}>
                When you are ready, hit the Start button below which will take
                you to the assessment page. The timer will stop when you hit the
                Submit button at the very end. Good luck!
              </p>
            </div>
          </div>
        </div>
      )}
      {!canSitAssessment() && lastAttempt && (
        <div className={`row mt-4 ${styles.yellowBox}`}>
          <h4>You cannot sit the assessment right now.</h4>
          <h6 className={'mt-3'}>
            You must wait {Math.floor(coolDownMilliseconds / 8.64e7)} days
            between attempts.
          </h6>
          <h6>
            You will be able to sit the assessment again in{' '}
            {Math.floor(
              (coolDownMilliseconds -
                (new Date().getTime() - lastAttempt.getTime())) /
                8.64e7
            )}{' '}
            days.
          </h6>
        </div>
      )}
      <div className={'d-flex justify-content-end align-items-center mt-4'}>
        {admin && (
          <CustomButton
            disabled={isLoading}
            type={'button'}
            text={'Toggle Admin Override'}
            variant={'transparent'}
            className={styles.OutlinedBtn}
            onClick={() => setAdminOverride(!adminOverride)}
          />
        )}
        {canSitAssessment() && (
          <CustomButton
            disabled={isLoading}
            type={'submit'}
            text={'Start'}
            variant={'primary'}
            icon={<SendIcon color={colors.whiteColor} />}
            iconSide={'right'}
            className={`ms-3 ${styles.widthButton}`}
            onClick={() => navigate('/assessment')}
          />
        )}
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default StartAssessmentModal
