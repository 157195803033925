import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import updateEmailStyles from './UpdateEmail.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../shared/customInputField/CustomInputField'
import { Formik, Form, FormikValues } from 'formik'
import * as Yup from 'yup'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import CustomModal from '../shared/customModal/customModal'
import { Auth } from 'aws-amplify'
import OrganizationService from '../../services/organization/organization.service'
import { useNavigate } from 'react-router-dom'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type UpdateCompanyDetailsProps = {
  onCancel: (isCancelled: boolean) => void // Callback function to handle cancel event
}

function UpdateEmail({ onCancel }: UpdateCompanyDetailsProps) {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pwdErrorModal, setPwdErrorModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [verificationModal, setVerificationModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [currentEmail, setCurrentEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [initialVal, setInitialVal] = useState(() => {
    return {
      email: '',
      currentPwd: '',
      newEmail: '',
      confirmNewEmail: '',
    }
  })

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  const loadOrganizationData = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await loadingService.await(orgService.getOrgData())
      const user = await Auth.currentAuthenticatedUser()
      setCurrentEmail(user.attributes.email)

      if (res !== null && res.status === 'success' && res.result !== null) {
        setIsLoading(false)
        setInitialVal({
          email: user.attributes.email,
          currentPwd: '',
          newEmail: '',
          confirmNewEmail: '',
        })
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [loadingService, orgService])

  useEffect(() => {
    loadOrganizationData()
  }, [loadOrganizationData])

  const verifyPassword = useCallback(
    async (values: FormikValues) => {
      setIsLoading(true)
      try {
        // Call signIn method to verify the password
        await Auth.signIn(initialVal.email, values.currentPwd)
        setIsLoading(false)
        return true
        // If signIn method doesn't throw an error, the password is correct
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setIsLoading(false)
        setPwdErrorModal(true)
        return false
      }
    },
    [initialVal.email]
  )

  const validationSchema = Yup.object().shape({
    currentPwd: Yup.string()
      .min(8, 'Password must be at least 8 characters.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      )
      .required('Password is required'),
    newEmail: Yup.string()
      .required('Please enter a new email')
      .email('Please enter a valid email address.'),
    confirmNewEmail: Yup.string()
      .required('Please confirm your new email')
      .email('Please enter a valid email address.')
      .oneOf(
        [Yup.ref('newEmail'), null],
        'Entered email does not match with the new email'
      ),
  })

  const verifyUpdateResponse = async (newEmail: string) => {
    setIsLoading(true)
    // Update organization email using orgService.updateOrgData

    try {
      const res = await loadingService.await(
        orgService.updateOrgData({ email: newEmail })
      )

      if (res !== null && res.status === 'success') {
        setIsLoading(false)
        setModal(true)
        setIsError(false)
        setModalMessage('Email updated successfully')
        setModal(true)
      }
    } catch (error) {
      setIsLoading(false)
      setModal(true)
      setIsError(true)
      setModalMessage(`${error} while verifying the email`)
    }
  }

  async function handleEmailVerification(): Promise<void> {
    setIsLoading(true)
    try {
      if (verificationCode !== '') {
        const res = await Auth.verifyCurrentUserAttributeSubmit(
          'email',
          verificationCode
        )

        if (res === 'SUCCESS') {
          await verifyUpdateResponse(newEmail)
          setModal(true)
          setIsLoading(false)

          // Display success message
          setModalMessage('Email update successful')
        } else {
          setModal(true)
          setModalMessage('Something went wrong while verifying the email')
          setIsLoading(false)
        }
      } else {
        setModal(true)
        setModalMessage('Please enter the verification code')
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      setModal(true)
      setIsError(true)
      setModalMessage(`Invalid verification code provided, please try again`)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    setSubmitting(true)
    setIsLoading(true)

    try {
      const pwdVerified = await verifyPassword(values)
      setNewEmail(values.newEmail.trim())

      if (pwdVerified) {
        const user = await Auth.currentAuthenticatedUser()
        const email = values.newEmail.trim()

        // Update user attributes using Auth.updateUserAttributes
        const attributes = {
          email: email,
          email_verified: 'true',
        }

        setIsLoading(true)
        const res1 = await Auth.updateUserAttributes(user, attributes)

        if (res1 === 'SUCCESS') {
          // setIsLoading(false)
          setVerificationModal(true)
          setIsError(false)
          setIsLoading(false)
        }
      }
    } catch (error: any) {
      setModal(true)
      setIsError(true)
      setModalMessage('Something went wrong while updating organization email')
      setIsLoading(false)
      setSubmitting(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const handleCancel = () => {
    onCancel(true)
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <CustomModal
        open={verificationModal}
        onCloseModal={(val) => {
          setIsLoading(false)
          setVerificationModal(val)
        }}
      >
        <div className={`${styles.modal} text-center`}>
          <div className={styles.modalTitle}>
            <h4>
              Enter the verification code sent to{' '}
              <span style={{ color: 'red' }}>{newEmail}</span>
            </h4>
          </div>
          <div className="mt-4 mb-4">
            <input
              type="input"
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter Verification Code *"
              style={{
                border: '2px solid',
                borderRadius: '5px',
                fontWeight: 'bold',
              }}
            />
          </div>

          <div className={updateEmailStyles.buttonDiv}>
            <CustomButton
              type="submit"
              text="Submit"
              disabled={isLoading}
              className="btn btn-primary"
              onClick={() => handleEmailVerification()}
            />
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={pwdErrorModal}
        onCloseModal={(val) => {
          setIsLoading(false)
          setPwdErrorModal(val)
        }}
      >
        <div className={styles.modal}>
          Wrong Password. Please try again later.
        </div>
      </CustomModal>

      <CustomModal
        open={modal}
        onCloseModal={(val) => {
          setIsLoading(false)
          setModal(val)
        }}
      >
        <div className={styles.modal}>
          <h3
            className={
              'text-almostBlack mb-5 d-flex justify-content-center align-items-center'
            }
          >
            {modalMessage}
          </h3>
          <div className={`mt-3 justify-content-between`}></div>
          <div className={'d-flex justify-content-between mt-5'}>
            <div>&nbsp;</div>
            <div className="d-flex justify-content-between">
              {!isError && (
                <div>
                  <CustomButton
                    type={'button'}
                    text={'Ok'}
                    variant={'primary'}
                    className={styles.modalBtn}
                    onClick={async () => {
                      setModal(false)
                      localStorage.removeItem('masterData')
                      localStorage.removeItem('token')
                      localStorage.removeItem('refreshToken')
                      localStorage.removeItem('tokenExpiration')
                      localStorage.removeItem('login_form')
                      await Auth.signOut()
                      navigate('/signIn?type=organizationSignIn')
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CustomModal>
      <Formik
        initialValues={initialVal}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form autoComplete="off">
            <div>
              <div>
                Your current email is{' '}
                <span className="text-bold gray-color-text">
                  {currentEmail}
                </span>
              </div>
              <div
                className={`mt-3 justify-content-between ${styles.borderBottomLight}`}
              >
                <CustomInputField
                  name={'currentPwd'}
                  type="password"
                  placeholder={'Confirm your current password *'}
                  className={'mt-2'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('currentPwd', event.target.value)
                  }
                />
              </div>
              <div
                className={`mt-4 justify-content-between ${styles.borderBottomLight}`}
              >
                <CustomInputField
                  name={'newEmail'}
                  placeholder={'New Email Address *'}
                  className={'mt-2'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('newEmail', event.target.value)
                  }
                />
                <CustomInputField
                  name={'confirmNewEmail'}
                  placeholder={'Confirm Email Address *'}
                  className={'mt-2'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('confirmNewEmail', event.target.value)
                  }
                />
              </div>
              <div className={'d-flex justify-content-between mt-5'}>
                <div>
                  <CustomButton
                    type={'button'}
                    className={`${styles.outlineBtn}`}
                    icon={<RecycleBinIcon />}
                    iconSide={'left'}
                    text={'Discard'} /* onClick={closeModal} */
                    onClick={() => handleCancel()}
                  />
                </div>
                <div>
                  <CustomButton
                    type={'submit'}
                    text={'Update Email'}
                    className={styles.modalBtn}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default UpdateEmail
