import { RequestStatus } from '../http/http.model'

export type OrgDetailsResponse = {
  id: string
  name: string
  ABN: string
  email: string
  phone: string
  address: string
  website: string
  industry: string
  type: string
  how_did_you_hear_about_us: string
  user_relationship: string
  financials_connected: boolean
  streetName: string
  city: string
  state: string
  postcode: string
  referral_text?: string
  accountReferralName?: string
  __jobs__: any
}

export type GetOrgResponse = {
  status: string
  result: OrgDetailsResponse | null
}

export type SearchOrgResponse = {
  status: string
  result: OrgDetailsResponse[]
}

export type UpdateOrgResponse = {
  status: string
  result: OrgDetailsResponse
}

export type CreateOrgResponse = {
  status: string
  result: OrgDetailsResponse
}

export type XeroRequestAccessResponse = {
  status: RequestStatus
  result: string
}

export type XeroConfirmAccessResponse = {
  status: RequestStatus
}

export enum MyobRequestStatus {
  Success = 'success',
  ExtractSuccess = 'extract_success',
  TokenExpired = 'reauthenticate',
  AccessDenied = 'access_denied',
  NoFiles = 'no_company_files',
  Error = 'error',
}

export type MyobRequestAccessResponse = {
  status: MyobRequestStatus
  result: string
}

export type MyobConfirmAccessResponse = {
  status: MyobRequestStatus
}

export type MyobExtractResponse = {
  status: MyobRequestStatus
}

export type MyobGetFilesResponse = {
  status: MyobRequestStatus
  result: { name: string; uri: string }[]
}
