import React, { useEffect, useState } from 'react'

interface ICountUp {
  hours: number
  minutes: number
  seconds: number
  doTick: boolean
  onTimeChange: (time: {
    hours: number
    minutes: number
    seconds: number
  }) => void // Add this prop
}

const TimerClock = ({
  hours = 0,
  minutes = 0,
  seconds = 0,
  doTick = true,
  onTimeChange,
}: ICountUp) => {
  const [time, setTime] = useState({ hours, minutes, seconds })
  const [lastTick, setLastTick] = useState(Date.now())
  const reset = () => setTime({ hours: 0, minutes: 0, seconds: 0 })
  const tick = () => {
    if (!doTick) {
      return
    }
    setLastTick(Date.now())
    if (time.hours === 99 && time.minutes === 59 && time.seconds === 59) reset()
    else if (time.minutes === 59 && time.seconds === 59) {
      setTime({ hours: time.hours + 1, minutes: 0, seconds: 0 })
    } else if (time.seconds === 59) {
      setTime({ hours: time.hours, minutes: time.minutes + 1, seconds: 0 })
    } else {
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds + 1,
      })
    }
  }

  useEffect(() => {
    setTimeout(() => tick(), 1000 - (Date.now() - lastTick))
    const timerId = setInterval(() => {
      tick()
      onTimeChange(time) // Call the callback function with the updated time
    }, 1000)
    return () => clearInterval(timerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTick, onTimeChange, time])

  return (
    <div>
      <h6 className={'gray-color-text'}>
        {time.hours > 0
          ? `${time.hours?.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`
          : `${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}
      </h6>
    </div>
  )
}

export default TimerClock
