import React from 'react'

interface Props {
  size?: number
  color?: string
}

const HideEyeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 22}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 22 20'}
    >
      <path
        d="m2 1 18 18M9.584 8.587a2 2 0 0 0 2.828 2.83M8.363 3.365A9.466 9.466 0 0 1 11 3c4 0 7.333 2.333 10 7-.778 1.361-1.612 2.524-2.503 3.488m-2.14 1.861C14.726 16.449 12.942 17 11 17c-4 0-7.333-2.333-10-7 1.369-2.395 2.913-4.175 4.632-5.341"
        stroke={color ?? '#9CA3AF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HideEyeIcon
