import React from 'react'

interface Props {
  size?: number
  color?: string
}

const EditIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 20} height={size ?? 20} fill="none">
      <path
        d="M6 6H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-3M13 4l3 3M6 14h3l8.5-8.5a2.121 2.121 0 0 0-3-3L6 11v3Z"
        stroke={color ?? '#1A3665'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
