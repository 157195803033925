import { useEffect, useMemo, useState } from 'react'
import styles from './assesmentCard.module.scss'
import colors from '../../styles/variables.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import RightArrowIcon from '../../icons/rightArrow.icon'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import DownArrowIcon from '../../icons/downArrow.icon'
import WrongIcon from '../../icons/wrong.icon'
import defaultPic from '../../images/avatar.png'
import { useNavigate } from 'react-router-dom'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import SuccessIcon from '../../icons/success.icon'
import { CandidateService } from '../../services/candidate/candidate.service'
import LoadingService from '../../services/loading/loading.service'

type ProfileStatusCardProps = {
  full_name: string
  email: string
  profilePic?: string
  topThree?: boolean
}

type UserProfilePages = {
  [key: string]: string | null
  gettingStart: string | null
  resumeImport: string | null
  qualification: string | null
  experience: string | null
  finish: string | null
}

const defaultPages: UserProfilePages = {
  gettingStart: null,
  resumeImport: null,
  qualification: null,
  experience: null,
  finish: null,
}

const ProfileStatusCard: React.FC<ProfileStatusCardProps> = ({
  full_name,
  email,
  topThree,
  profilePic,
}) => {
  const navigate = useNavigate()

  // State variables
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedPages, setLoadedPages] = useState<UserProfilePages>(defaultPages)
  const [score, setScore] = useState<number>(0)

  // Services
  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  /**
   * Calculates the score of the user profile page based on the number of completed pages
   * @param userProfilePage User profile page object
   * @returns Score of the user profile page as a percentage with 2 decimal places
   */
  const calculateScore = (userProfilePage: UserProfilePages): number => {
    const totalKeys = Object.keys(userProfilePage).length
    const completedKeys = Object.values(userProfilePage).filter(
      (value) => value === 'complete'
    ).length
    const myScore = (completedKeys / totalKeys) * 100
    return parseFloat(myScore.toFixed(2)) // Returning score as a number with 2 decimal places
  }

  /**
   * Navigates to the first incomplete page
   * @returns void
   */
  const handleCompleteNow = (): void => {
    if (loadedPages) {
      for (const key in loadedPages) {
        if (
          Object.hasOwn(loadedPages, key) &&
          loadedPages[key] !== 'complete'
        ) {
          navigate(`/${key}`)
          return
        }
      }
    }
    navigate('/gettingStart')
  }

  /**
   * Converts the key of the user profile page to a label
   * @param key The key of the user profile page
   * @returns The label of the user profile page
   */
  const getKeyLabel = (key: string): string => {
    switch (key) {
      case 'gettingStart':
        return 'Getting Started'
      case 'qualification':
        return 'Qualifications'
      default:
        return key
          .split(/(?=[A-Z])/)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    }
  }

  // On page load, get the user profile pages and set the state variable
  useEffect(() => {
    loadingService
      .await(candidateService.getProfilePages())
      .then((res: string | null) => {
        if (res === null) {
          return
        }
        const parsedRes = JSON.parse(res)
        // The user profile pages response will only include completed/skipped pages.
        // So, we need to add the incomplete pages (from prev) to the response
        setLoadedPages((prev) => ({ ...prev, ...parsedRes }))
      })
  }, [candidateService, loadingService])

  // When the user profile pages are loaded, calculate the score
  useEffect(() => {
    if (loadedPages) {
      const calculatedScore = calculateScore(loadedPages)
      setScore(calculatedScore)
    }
  }, [loadedPages])

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <div
        className={topThree ? styles.topthreeCard : styles.cardProfile}
        style={{ borderColor: colors.gray200 }}
      >
        <div className={'d-flex'}>
          {profilePic != '' ? (
            <img
              src={profilePic}
              alt={'avatar'}
              className={styles.profilePic}
            />
          ) : (
            <img
              src={defaultPic}
              alt={'defaultAvatar'}
              className={styles.profilePic}
            />
          )}
          <div className={'ms-4'}>
            <h2 className={'text-almostBlack text-bolder'}>{full_name}</h2>
            <h6 className={'text-normal gray-color-text mt-1'}>{email}</h6>
            <h6
              className={`text-teritary-color pointer-event mt-1 ${styles.link}`}
              onClick={() => navigate('/profile')}
            >
              View Profile
            </h6>
          </div>
        </div>
        <div
          className={`mt-4 ${open ? styles.openProfileStatus : styles.profileStatus}`}
        >
          <div className={'d-flex justify-content-between align-items-center'}>
            <h4 className={'text-almostBlack text-bolder'}>Profile Status</h4>
            <div className={'d-flex align-items-center'}>
              <div style={open ? { width: '20px' } : { width: '60px' }}>
                <CircularProgressbar
                  value={score}
                  text={open ? '' : `${score}%`}
                  styles={buildStyles({
                    pathColor: `${
                      score < 50
                        ? colors.dangerColor
                        : score < 75
                          ? colors.orangeColor
                          : colors.successColor
                    }`,
                    textSize: '18px',
                    pathTransitionDuration: 1,
                  })}
                />
              </div>
              <div
                className={`ms-3 ${open ? styles.rotateArrow : styles.rotateBack} `}
                onClick={() => setOpen(!open)}
              >
                <DownArrowIcon />
              </div>
            </div>
          </div>
          <div className={open ? styles.displayBlock : styles.displayNone}>
            {loadedPages &&
              Object.entries(loadedPages).map(([key, value]) => (
                <div className={'d-flex mt-2'} key={key}>
                  {value === 'complete' ? (
                    <SuccessIcon color={colors.primaryColor} />
                  ) : (
                    <WrongIcon color={colors.dangerColor} />
                  )}
                  <p className={'ms-3 text-almostBlack'}>{getKeyLabel(key)}</p>
                </div>
              ))}
          </div>
        </div>
        {score !== 100 && (
          <CustomButton
            text={'Complete Now'}
            onClick={handleCompleteNow}
            icon={<RightArrowIcon color={colors.almostBlack} />}
            iconSide={'right'}
            className={`mt-4 ${styles.outlineProgressBtn}`}
          />
        )}
      </div>
    </div>
  )
}

export default ProfileStatusCard
