import {
  type ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styles from './EmailVerify.module.scss'
import Email from '../../images/email.png'
import CustomButton from '../shared/CustomButton/customButton'
import RightArrow from '../../icons/rightArrow.icon'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Form, Formik } from 'formik'
import CustomInputField from '../shared/customInputField/CustomInputField'
import * as Yup from 'yup'
import UserService from '../../services/user/user.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LoadingService from '../../services/loading/loading.service'
import CustomModal from '../shared/customModal/customModal'
import { UserContext } from '../../context'
import SendIcon from '../../icons/send.icon'
import { CandidateService } from '../../services/candidate/candidate.service'
import OrganizationService from '../../services/organization/organization.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type EmailVerifyValues = {
  code: string
  emailUpdate: string | null
}

const EmailVerify = () => {
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const candidateService = useMemo(() => new CandidateService(), [])
  const orgService = useMemo(() => new OrganizationService(), [])

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [email, setEmail] = useState('')

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  useEffect(() => {
    loadingService.await(userService.isLoggedIn()).then((res) => {
      loadingService.setReason('getIsLoggedIn', false)
      if (!res) {
        navigate('/')
      }
    })
    loadingService
      .await(userService.getAttribute('email_verified'))
      .then((res) => {
        loadingService.setReason('getIsEmailVerified', false)
        if (res === 'true') {
          navigate('/lastPage')
        }
      })
  }, [loadingService, navigate, userService])

  /**
   * Set email of cognito logged in user only when the component is mounted.
   */
  useEffect(() => {
    loadingService.await(
      Auth.currentAuthenticatedUser().then((res) => {
        setEmail(res.attributes.email)
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Please enter the verification code.')
      .length(6, 'Your verification code should be six digits long.'),
    emailUpdate: Yup.string()
      .email('Please enter a valid email address.')
      .notRequired()
      .nullable(),
  })

  const resendCode = async function () {
    try {
      await loadingService.await(Auth.verifyCurrentUserAttribute('email'))
      navigate(0)
    } catch (e: any) {
      setErrorMessage(e.message)
      setErrorModel(true)
    }
  }

  const handleSubmit = async function (
    values: EmailVerifyValues,
    { setFieldError }: { setFieldError: (field: string, error: string) => void }
  ) {
    try {
      const role = (await userService.getUserRole())?.role ?? ''
      const res = await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        values.code
      )
      if (res === 'SUCCESS') {
        let userD: any = user.userData
        userD.email = email
        user.setUserData(userD)
        if (role === 'user') {
          await candidateService.isProfileCompleted().then(async (res) => {
            if (res) {
              navigate('/dashboard')
            } else {
              const page = await candidateService.getLastPage()
              if (page === null) {
                navigate('/gettingStart')
              } else {
                navigate('/' + page)
              }
            }
          })
        } else if (role === 'org_user') {
          if (user.userData) {
            navigate('/' + user.userData.last_page)
          } else {
            navigate('/hirefor')
          }
        } else if (role === 'admin') {
          navigate('/admin')
        } else {
          navigate('/lastPage')
        }
      } else {
        setFieldError('code', 'Error verifying email.')
      }
    } catch (err) {
      if (
        err instanceof Error &&
        (err.name === 'CodeMismatchException' ||
          err.name === 'ExpiredCodeException')
      ) {
        setFieldError('code', err.message)
      } else {
        setFieldError('code', 'Failed to verify email.')
      }
    }
  }

  const changeEmail = async (
    email: string,
    setFieldError: (error: string) => void
  ) => {
    const validate = await loadingService.await(
      userService.validateEmailAddress(email)
    )
    if (
      validate === null ||
      validate.status !== 'success' ||
      validate.result === true
    ) {
      setFieldError('This email is already in use.')
      return
    }
    try {
      let currentUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(currentUser, {
        email: email,
      })
      currentUser = await Auth.currentAuthenticatedUser()
      const res = await loadingService.await(
        userService.updateUserData({ email })
      )
      if (currentUser.attributes['custom:role'] === 'org_user') {
        await loadingService.await(orgService.updateOrgData({ email }))
      }
      if (res === null) {
        setFieldError('Failed to update email.')
        return
      }
      let userD: any = user.userData
      userD.email = email
      user.setUserData(userD)
      setModal(false)
      setEmail(email)
    } catch (e: any) {
      setModal(false)
      setErrorMessage(e.message)
      setErrorModel(true)
    }
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik
          initialValues={{
            code: '',
            emailUpdate: null,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, setFieldError }) => (
            <Form>
              <div
                className={`d-flex flex-column justify-content-center align-items-center mt-3 ${styles.mainContent}`}
              >
                <img src={Email} alt={'email'} />
                <div className={styles.verifyContent}>
                  <h1 className={'text-almostBlack mt-5 text-bolder'}>
                    Verify Your Email
                  </h1>
                  <h6 className={'text-bold gray-color-text mt-2 text-center'}>
                    We sent a verification email to {email}.
                  </h6>
                  <h6 className={'text-bold gray-color-text mt-2'}>
                    {' '}
                    Please verify your email before continuing.
                  </h6>
                  <span>
                    <CustomInputField
                      name={'code'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('code', event.target.value)
                      }
                      placeholder={'Code*'}
                      className={'mt-3 mb-3'}
                    />
                    <CustomButton
                      type={'submit'}
                      text={'Enter Code'}
                      icon={<RightArrow />}
                      iconSide={'right'}
                      className={'mt-2'}
                      disabled={isLoading}
                    />
                  </span>
                </div>
                <h6 className={styles.linkLine}>
                  <span className={'text-normal gray-color-text ms-3 me-2'}>
                    Didn’t receive an email? Make sure you check your Junk/Spam
                    folder{' '}
                    <span className={styles.resendBtn}>
                      <span
                        className={`text-normal pointer ${styles.linkColour}`}
                        onClick={() => {
                          resendCode().then()
                        }}
                      >
                        Send the Code Again <SendIcon />
                      </span>
                      <span className={'ms-2 text-normal gray-color-text'}>
                        or
                      </span>
                      <span
                        className={`text-normal pointer ms-2 ${styles.linkColour}`}
                        onClick={() => setModal(true)}
                      >
                        Re-enter email
                      </span>
                    </span>
                  </span>
                </h6>
              </div>
              {modal && (
                <CustomModal
                  title={'Update your Email'}
                  open={modal}
                  onCloseModal={(val) => setModal(val)}
                >
                  {isLoading && <LoadingSpinner />}
                  <div
                    className={`${styles.modal} ${isLoading && loadingStyles.app_while_loading}`}
                  >
                    <CustomInputField
                      name={'emailUpdate'}
                      placeholder={'New Email'}
                      className={'mt-3'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('emailUpdate', event.target.value)
                      }}
                    />
                    <div
                      className={
                        'mt-3 d-flex justify-content-center align-items-center w-100'
                      }
                    >
                      <CustomButton
                        text={'Save Changes'}
                        disabled={isLoading || errors.emailUpdate !== undefined}
                        className={styles.widthBtn}
                        onClick={() => {
                          if (
                            values.emailUpdate === null ||
                            values.emailUpdate === ''
                          ) {
                            setFieldError(
                              'emailUpdate',
                              'Please enter a valid email address.'
                            )
                            return
                          }
                          changeEmail(values.emailUpdate, (error: string) =>
                            setFieldError('emailUpdate', error)
                          )
                        }}
                      />
                    </div>
                  </div>
                </CustomModal>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={async () => {
          setErrorModel(false)
          await Auth.signOut()
          navigate('/')
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default EmailVerify
