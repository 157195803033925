import React from 'react'

interface Props {
  size?: number
  color?: string
}

const DegreeCapIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 22} height={size ?? 16} fill="none">
      <path
        d="M21 5 11 1 1 5l10 4 10-4Zm0 0v6M5 6.6V12c0 .796.632 1.559 1.757 2.121 1.126.563 2.652.88 4.243.88 1.591 0 3.117-.317 4.243-.88C16.368 13.56 17 12.796 17 12.001V6.6"
        stroke={color ?? '#9CA3AF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DegreeCapIcon
