import { useContext, useEffect, useMemo, useState, useRef } from 'react'
import styles from './Profile.module.scss'
import TopNavigation from '../topNavigation/topNavigation'
import CustomImageUpload from '../shared/CustomImageUpload/customImageUpload'
import PersonalProfile, { PersonalProfileProps } from './personalProfile'
import EducationProfile from './educationProfile'
import colors from '../../styles/variables.module.scss'
import EditIcon from '../../icons/edit.icon'
import BagIcon from '../../icons/bag.icon'
import CustomButton from '../shared/CustomButton/customButton'
import ResultCard from './resultCard'
import ResumeIcon from '../../icons/resume.icon'
import FileUpload from '../shared/FileUpload/fileUpload'
import CertificateProfile from './certificateProfile'
import { useNavigate } from 'react-router-dom'
import LoadingService from '../../services/loading/loading.service'
import UserService from '../../services/user/user.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import { UploadedFile } from '../../services/file/file.model'
import FileService from '../../services/file/file.service'
import CloseIcon from '../../icons/close.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import PreferencesProfile from './preferencesProfile'
import WorkExperienceProfile from './workExperienceProfile'
import { CandidateService } from '../../services/candidate/candidate.service'
import OverviewProfile from './overviewProfile'
import KeySkillProfile from './keySkillProfile'
import ReferenceIcon from '../../icons/reference.icon'
import PlusIcon from '../../icons/plus.icon'
import EmailIcon from '../../icons/email.icon'
import CustomModal from '../shared/customModal/customModal'
import AddReferenceModal from './addReferenceModal'
import SendIcon from '../../icons/send.icon'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import ReviewModal, { Responses } from './reviewModal'
import { MasterDataContext } from '../../context/masterData'
import DefaultCloseIcon from '../../icons/defaultClose.icon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export const matches = (a: string, b: string): boolean => {
  return (
    a.toLowerCase().trim() === b.toLowerCase().trim() ||
    b.toLowerCase().trim().includes(a.toLowerCase().trim()) ||
    a.toLowerCase().trim().includes(b.toLowerCase().trim())
  )
}

const Profile = () => {
  const navigate = useNavigate()
  const masterData = useContext(MasterDataContext)
  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])

  const [isLoading, setIsLoading] = useState(false)

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])
  const [editable, setEditable] = useState(false)
  const [reviewModal, setReviewModal] = useState(false)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  })
  const [completedAssessment, setCompletedAssessment] = useState(false)
  const [profileImage, setProfileImage] = useState<UploadedFile | null>(null)
  const [resume, setResume] = useState<UploadedFile | null>(null)
  const [coverLetter, setCoverLetter] = useState<UploadedFile | null>(null)
  const [taskExpertise, setTaskExpertise] = useState<string[]>([])
  const [systemExpertise, setSystemExpertise] = useState<string[]>([])
  const [industryExpertise, setIndustryExpertise] = useState<string[]>([])
  const [savedTaskExpertise, setSavedTaskExpertise] = useState<string[]>([])
  const [savedSystemExpertise, setSavedSystemExpertise] = useState<string[]>([])
  const [savedIndustryExpertise, setSavedIndustryExpertise] = useState<
    string[]
  >([])
  const [systemExpertiseInput, setSystemExpertiseInput] = useState('')
  const [taskExpertiseInput, setTaskExpertiseInput] = useState('')
  const [industryExpertiseInput, setIndustryExpertiseInput] = useState('')
  const [profileImageError, setProfileImageError] = useState('')
  const [resumeError, setResumeError] = useState('')
  const [coverLetterError, setCoverLetterError] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [referees, setReferees] = useState<
    {
      id: string
      name: string
      email: string
      phone: string
      relationship: string
      organization: string
      status: string
      response1: string
      response2: string
      response3: string
      response4: string
      additionalComments: string
    }[]
  >([])
  const dragItem = useRef(0)
  const dragOverItem = useRef(0)
  const [expertiseUpdateFlag, setExpertiseUpdateFlag] = useState(false)
  const [refereeModalOpen, setRefereeModalOpen] = useState(false)
  const [refereeUpdateFlag, setRefereeUpdateFlag] = useState(false)
  const [currentRefereeIndex, setCurrentRefereeIndex] = useState<number | null>(
    null
  )
  const [overviewUpdateFlag, setOverviewUpdateFlag] = useState(false)
  const [keySkillsUpdateFlag, setKeySkillsUpdateFlag] = useState(false)
  const [taskExpertiseSuggestions, setTaskExpertiseSuggestions] = useState<
    string[]
  >([])
  const [systemExpertiseSuggestions, setSystemExpertiseSuggestions] = useState<
    string[]
  >([])
  const [industryExpertiseSuggestions, setIndustryExpertiseSuggestions] =
    useState<string[]>([])
  const [relationshipOptions, setRelationshipOptions] = useState<any[]>([])
  const [systemExpTagValidationErrors, setSystemExpTagValidationErrors] =
    useState<string>('')
  const [taskExpTagValidationErrors, setTaskExpTagValidationErrors] =
    useState<string>('')
  const [industryExpTagValidationErrors, setIndustryExpTagValidationErrors] =
    useState<string>('')

  useEffect(() => {
    //scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (masterData?.masterData) {
      setTaskExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'tasks_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      setSystemExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'systems_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      setIndustryExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'industry_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      setRelationshipOptions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'referee_relationship')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  useEffect(() => {
    loadingService
      .await(userService.getUserDataT())
      .then((res) => {
        if (res !== null) {
          setFullName(res.full_name)
          setEmail(res.email)
        }
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })

    loadingService.await(userService.getProfileImage()).then(async (res) => {
      if (res !== null) {
        setProfileImage(await fileService.readBackendImage(res))
      }
    })

    loadingService
      .await(candidateService.getCandidateDataT())
      .then((res) => {
        if (res !== null) {
          setTaskExpertise(res.task_expertise ?? [])
          setSystemExpertise(res.system_expertise ?? [])
          setIndustryExpertise(res.industry_expertise ?? [])
          setSavedTaskExpertise(res.task_expertise ?? [])
          setSavedSystemExpertise(res.system_expertise ?? [])
          setSavedIndustryExpertise(res.industry_expertise ?? [])
          // eslint-disable-next-line @typescript-eslint/naming-convention

          if (res.resume_key) {
            const resume_key = res.resume_key?.split('/')
            const resume_url: any = {
              name: resume_key[2],
              contents: '',
              type: 'url',
            }
            setResume(resume_url)
          }

          if (res.cover_letter_key) {
            const cover_letter_key = res.cover_letter_key?.split('/')
            const cover_letter_url: any = {
              name: cover_letter_key[2],
              contents: '',
              type: 'url',
            }

            setCoverLetter(cover_letter_url)
          }
        }
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })

    loadingService
      .await(candidateService.getAssessmentResults())
      .then((res) => {
        if (res !== null && res.status === 'success') {
          setCompletedAssessment(true)
        }
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })

    loadingService.await(userService.isLoggedIn()).then((res) => {
      if (!res) {
        navigate('/')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, loadingService, userService, candidateService])

  useEffect(() => {
    loadingService
      .await(candidateService.getReferees())
      .then((res) => {
        if (res !== null && res.status === 'success') {
          setReferees(res.result)
        } else if (res !== null && res.status === 'no_data') {
          setReferees([])
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [loadingService, candidateService, refereeUpdateFlag])

  const updateProfileImage = async (image: UploadedFile) => {
    if (image.original?.size && image.original?.size > 10 * 1024 * 1024) {
      setProfileImageError('Image size must be less than 10MB')
      return
    }
    const res = await loadingService.await(
      userService.updateProfileImage(image)
    )
    if (res !== null) {
      setProfileImageError('')
      setProfileImage(image)
    } else {
      setProfileImageError('Failed to update profile image')
    }
  }

  const updateResume = async (file: UploadedFile) => {
    if (file.original?.size && file.original?.size > 10 * 1024 * 1024) {
      setResumeError('File size must be less than 10MB')
      return
    }
    await loadingService.await(candidateService.updateResume(file))
    setResumeError('')
    setResume(file)
  }

  const updateCoverLetter = async (file: UploadedFile) => {
    if (file.original?.size && file.original?.size > 10 * 1024 * 1024) {
      setCoverLetterError('File size must be less than 10MB')
      return
    }
    await loadingService.await(candidateService.updateCoverLetter(file))
    setCoverLetterError('')
    setCoverLetter(file)
  }

  const onUpdatePersonalInfo = (values: PersonalProfileProps) => {
    if (values.fullName) {
      setFullName(values.fullName)
    }
    if (values.email) {
      setEmail(values.email)
    }
  }

  function onUpdateQualifications() {
    setOverviewUpdateFlag(!overviewUpdateFlag)
  }

  function onUpdateCertifications() {
    setOverviewUpdateFlag(!overviewUpdateFlag)
  }

  function onUpdateWorkExperience() {
    setOverviewUpdateFlag(!overviewUpdateFlag)
  }

  const dragStartSystem = (e: any, position: any) => {
    dragItem.current = position
  }

  const dragEnterSystem = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const dropSystem = () => {
    const copyListItems = [...systemExpertise]
    const dragItemContent = copyListItems[dragItem.current]
    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = 0
    dragOverItem.current = 0
    setSystemExpertise(copyListItems)
  }

  const touchSystem = () => {
    const copyListItems = [...systemExpertise]
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current]
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setSystemExpertise(copyListItems)
  }

  const dragStartTask = (e: any, position: any) => {
    dragItem.current = position
  }

  const dragEnterTask = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const dropTask = () => {
    const copyListItems = [...taskExpertise]
    const dragItemContent = copyListItems[dragItem.current]

    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)

    dragItem.current = 0
    dragOverItem.current = 0
    setTaskExpertise(copyListItems)
  }

  const touchTask = () => {
    const copyListItems = [...taskExpertise]
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current]
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setTaskExpertise(copyListItems)
  }

  const dragStartIndustry = (e: any, position: any) => {
    dragItem.current = position
  }

  const dragEnterIndustry = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const dropIndustry = () => {
    const copyListItems = [...industryExpertise]
    const dragItemContent = copyListItems[dragItem.current]
    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)

    dragItem.current = -1
    dragOverItem.current = -1
    setIndustryExpertise(copyListItems)
  }

  const touchIndustry = () => {
    const copyListItems = [...industryExpertise]
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current]
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setIndustryExpertise(copyListItems)
  }

  const addSystemExpertise = () => {
    setExpertiseUpdateFlag(true)
    if (
      systemExpertiseInput.trim() !== '' &&
      systemExpertise.find((exp) => exp === systemExpertiseInput) === undefined
    ) {
      setSystemExpertise((prev) => [...prev, systemExpertiseInput])
    } else {
      setSystemExpTagValidationErrors('Please enter a valid system expertise')
      setSystemExpertiseInput('')
    }
    setSystemExpertiseInput('')
  }

  const addTaskExpertise = () => {
    setExpertiseUpdateFlag(true)
    if (
      taskExpertiseInput.trim() !== '' &&
      taskExpertise.find((exp) => exp === taskExpertiseInput) === undefined
    ) {
      setTaskExpertise((prev) => [...prev, taskExpertiseInput])
    } else {
      setTaskExpTagValidationErrors('Please enter a valid task expertise')
      setTaskExpertiseInput('')
    }
    setTaskExpertiseInput('')
  }

  const addIndustryExpertise = () => {
    setExpertiseUpdateFlag(true)
    if (
      industryExpertiseInput.trim() !== '' &&
      industryExpertise.find((exp) => exp === industryExpertiseInput) ===
        undefined
    ) {
      setIndustryExpertise((prev) => [...prev, industryExpertiseInput])
    } else {
      setIndustryExpTagValidationErrors(
        'Please enter a valid industry expertise'
      )
      setIndustryExpertiseInput('')
    }
    setIndustryExpertiseInput('')
  }

  const removeTaskExpertise = (tag: string) => {
    setExpertiseUpdateFlag(true)
    setTaskExpertise((prev) => prev.filter((exp) => exp !== tag))
  }

  const removeSystemExpertise = (tag: string) => {
    setExpertiseUpdateFlag(true)
    setSystemExpertise((prev) => prev.filter((exp) => exp !== tag))
  }

  const removeIndustryExpertise = (tag: string) => {
    setExpertiseUpdateFlag(true)
    setIndustryExpertise((prev) => prev.filter((exp) => exp !== tag))
  }

  const saveExpertise = async () => {
    const res = await loadingService.await(
      candidateService.updateCandidateData({
        task_expertise: taskExpertise,
        system_expertise: systemExpertise,
        industry_expertise: industryExpertise,
      })
    )
    if (res !== null && res.status === 'success') {
      setSavedTaskExpertise(taskExpertise)
      setSavedSystemExpertise(systemExpertise)
      setSavedIndustryExpertise(industryExpertise)
      setEditable(false)
      setOverviewUpdateFlag(!overviewUpdateFlag)
      setKeySkillsUpdateFlag(!keySkillsUpdateFlag)
    }
  }

  const handelResetForm = () => {
    setSystemExpertiseInput('')
    setTaskExpertiseInput('')
    setIndustryExpertiseInput('')
  }

  const removeReferee = async (id: string): Promise<void> => {
    try {
      const res = await loadingService.await(
        candidateService.removeReferee([{ id }])
      )
      if (res !== null && res.status === 'success') {
        setRefereeUpdateFlag(!refereeUpdateFlag)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const requestRefereeReview = async (id: string): Promise<void> => {
    try {
      const res = await loadingService.await(
        candidateService.requestRefereeComment({ id })
      )
      if (res !== null && res.status === 'success') {
        setRefereeUpdateFlag(!refereeUpdateFlag)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const openResumePDFInNewTab = async () => {
    const url: any = await candidateService.getPreSignUrlResume()
    const pdfUrl = url.result
    window.open(pdfUrl, '_blank')
  }

  const openCoverLetterPDFInNewTab = async () => {
    const url: any = await candidateService.getPreSignUrlCoverLetter()
    const pdfUrl = url.result
    window.open(pdfUrl, '_blank')
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation dashboard tabValue={'1'} />
        <div className={styles.content}>
          <div className={styles.leftContent}>
            <div className={`d-flex align-items-center`}>
              <div>
                <CustomImageUpload
                  maxSize={2 * 1024 * 1024}
                  oversizeError={'Image size must be less than 2MB'}
                  onError={setProfileImageError}
                  disabled={isLoading}
                  defaultFile={profileImage?.contents}
                  id={'profile'}
                  fileSelect={async (file: File) => {
                    updateProfileImage(await fileService.readFile(file))
                  }}
                />
                {profileImageError && (
                  <div className={styles.error}>{profileImageError}</div>
                )}
              </div>
              <div className={'ms-5'}>
                <h2 className={'text-almostBlack'}>{fullName}</h2>
                <h6 className={'gray-color-text text-normal'}>{email}</h6>
              </div>
            </div>
            {completedAssessment && (
              <div className={styles.assessmentResultMobile}>
                <ResultCard />
              </div>
            )}
            <div className={`mt-5 ${styles.contentBox}`}>
              <PreferencesProfile pageIsLoading={isLoading} />
            </div>
            <div className={styles.contentBox}>
              <PersonalProfile
                onUpdate={onUpdatePersonalInfo}
                pageIsLoading={isLoading}
              />
            </div>
            <div className={styles.contentBox}>
              <OverviewProfile
                pageIsLoading={isLoading}
                updateFlag={overviewUpdateFlag}
              />
            </div>
            <div className={styles.contentBox}>
              <KeySkillProfile
                pageIsLoading={isLoading}
                updateFlag={keySkillsUpdateFlag}
              />
            </div>
            <div className={`mt-5 ${styles.contentBox}`}>
              <EducationProfile
                onUpdate={onUpdateQualifications}
                pageIsLoading={isLoading}
              />
            </div>
            <div className={`mt-5 ${styles.contentBox}`}>
              <CertificateProfile
                onUpdate={onUpdateCertifications}
                pageIsLoading={isLoading}
              />
            </div>
            <div className={`mt-5 ${styles.contentBox}`}>
              <div
                className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
              >
                <div className={'d-flex align-items-center'}>
                  <BagIcon color={colors.blackColor} />
                  <h4 className={'text-almostBlack ms-3'}>Expertise</h4>
                </div>
                <div
                  onClick={() => {
                    if (!isLoading) {
                      setTaskExpertise(savedTaskExpertise)
                      setSystemExpertise(savedSystemExpertise)
                      setIndustryExpertise(savedIndustryExpertise)
                      setEditable(!editable)
                    }
                  }}
                  className={'pointer'}
                >
                  {editable ? (
                    <div
                      onClick={() => {
                        if (!isLoading) {
                          handelResetForm()
                          setExpertiseUpdateFlag(false)
                        }
                      }}
                    >
                      <CloseIcon />
                    </div>
                  ) : (
                    <EditIcon />
                  )}
                </div>
              </div>
              <div>
                <div
                  className={styles.tagBox}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <h5>Task Expertise</h5>
                  <div className={'d-flex align-items-center w-100 flex-wrap'}>
                    {taskExpertise.map((exp: string, index) => (
                      <div
                        key={exp}
                        className={`me-3 ${styles.payableTag}`}
                        draggable={editable}
                      >
                        <div
                          draggable={editable}
                          onDragStart={(e) => dragStartTask(e, index)}
                          onDragEnter={(e) => dragEnterTask(e, index)}
                          onDragEnd={dropTask}
                          onTouchStart={(e) => dragStartTask(e, index)}
                          onTouchMove={(e) => dragEnterTask(e, index)}
                          onTouchEnd={touchTask}
                        >
                          {exp}
                        </div>
                        {editable && (
                          <div
                            className={styles.tagDelete}
                            onClick={() => removeTaskExpertise(exp)}
                          >
                            <DefaultCloseIcon />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {editable && (
                  <>
                    <div className={styles.bottomContent}>
                      <div className={styles.searchBar}>
                        <CustomDropdown
                          placeHolder={'New task expertise'}
                          dataList={taskExpertiseSuggestions
                            .filter((e) => matches(e, taskExpertiseInput))
                            .map((e) => ({
                              text: e,
                              value: e,
                            }))}
                          onChange={(e: any) => {
                            setTaskExpTagValidationErrors('')
                            setTaskExpertiseInput(e)
                          }}
                          getSelectedItem={(item) => {
                            setTaskExpertiseInput(item.value)
                            setTaskExpTagValidationErrors('')
                          }}
                          selectedValue={{
                            text: taskExpertiseInput,
                            value: taskExpertiseInput,
                          }}
                          sortDropdown={true}
                        />
                      </div>
                      <CustomButton
                        text={'Add Task Expertise'}
                        className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                        onClick={addTaskExpertise}
                        disabled={isLoading || taskExpertiseInput.length === 0}
                      />
                    </div>
                    {taskExpTagValidationErrors.trim() !== '' && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-16px',
                          position: 'relative',
                          marginBottom: '22px',
                        }}
                      >
                        {taskExpTagValidationErrors}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                <div
                  className={styles.tagBox}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <h5>System Expertise</h5>
                  <div className={'d-flex align-items-center w-100 flex-wrap'}>
                    {systemExpertise.map((exp: string, index) => (
                      <div
                        key={index}
                        className={`me-3 ${styles.payableTag}`}
                        draggable={editable}
                      >
                        <div
                          draggable={editable}
                          onDragStart={(e) => dragStartSystem(e, index)}
                          onDragEnter={(e) => dragEnterSystem(e, index)}
                          onDragEnd={dropSystem}
                          onTouchStart={(e) => dragStartSystem(e, index)}
                          onTouchMove={(e) => dragEnterSystem(e, index)}
                          onTouchEnd={touchSystem}
                        >
                          {exp}
                        </div>
                        {editable && (
                          <div
                            className={styles.tagDelete}
                            onClick={() => removeSystemExpertise(exp)}
                          >
                            <DefaultCloseIcon />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {editable && (
                  <>
                    <div className={styles.bottomContent}>
                      <div className={styles.searchBar}>
                        <CustomDropdown
                          placeHolder={'New system expertise'}
                          dataList={systemExpertiseSuggestions
                            .filter((e) => matches(e, systemExpertiseInput))
                            .map((e) => ({
                              text: e,
                              value: e,
                            }))}
                          onChange={(e: any) => {
                            setSystemExpTagValidationErrors('')
                            setSystemExpertiseInput(e)
                          }}
                          getSelectedItem={(item) => {
                            setSystemExpertiseInput(item.value)
                            setSystemExpTagValidationErrors('')
                          }}
                          selectedValue={{
                            text: systemExpertiseInput,
                            value: systemExpertiseInput,
                          }}
                          sortDropdown={true}
                        />
                      </div>
                      <CustomButton
                        text={'Add System Expertise'}
                        className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                        onClick={addSystemExpertise}
                        disabled={
                          isLoading || systemExpertiseInput.length === 0
                        }
                      />
                    </div>
                    {systemExpTagValidationErrors.trim() !== '' && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-16px',
                          position: 'relative',
                          marginBottom: '22px',
                        }}
                      >
                        {systemExpTagValidationErrors}
                      </div>
                    )}
                  </>
                )}
              </div>

              <div>
                <div
                  className={styles.tagBox}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <h5>Industry Expertise</h5>
                  <div className={'d-flex align-items-center w-100 flex-wrap'}>
                    {industryExpertise.map((exp: string, index) => (
                      <div
                        key={index}
                        className={`me-3 ${styles.payableTag}`}
                        draggable={editable}
                      >
                        <div
                          draggable={editable}
                          onDragStart={(e) => dragStartIndustry(e, index)}
                          onDragEnter={(e) => dragEnterIndustry(e, index)}
                          onDragEnd={dropIndustry}
                          onTouchStart={(e) => dragStartIndustry(e, index)}
                          onTouchMove={(e) => dragEnterIndustry(e, index)}
                          onTouchEnd={touchIndustry}
                        >
                          {exp}
                        </div>
                        {editable && (
                          <div
                            className={styles.tagDelete}
                            onClick={() => removeIndustryExpertise(exp)}
                          >
                            <DefaultCloseIcon />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {editable && (
                  <>
                    <div className={styles.bottomContent}>
                      <div className={styles.searchBar}>
                        <CustomDropdown
                          placeHolder={'New industry expertise'}
                          dataList={industryExpertiseSuggestions
                            .filter((e) => matches(e, industryExpertiseInput))
                            .map((e) => ({
                              text: e,
                              value: e,
                            }))}
                          onChange={(e: any) => {
                            setIndustryExpTagValidationErrors('')
                            setIndustryExpertiseInput(e)
                          }}
                          getSelectedItem={(item) => {
                            setIndustryExpertiseInput(item.value)
                            setIndustryExpTagValidationErrors('')
                          }}
                          selectedValue={{
                            text: industryExpertiseInput,
                            value: industryExpertiseInput,
                          }}
                          sortDropdown={true}
                        />
                      </div>
                      <CustomButton
                        text={'Add Industry Expertise'}
                        className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                        onClick={addIndustryExpertise}
                        disabled={
                          isLoading || industryExpertiseInput.length === 0
                        }
                      />
                    </div>
                    {industryExpTagValidationErrors.trim() !== '' && (
                      <div
                        className={styles.yupError}
                        style={{
                          marginTop: '-16px',
                          position: 'relative',
                          marginBottom: '22px',
                        }}
                      >
                        {industryExpTagValidationErrors}
                      </div>
                    )}
                  </>
                )}
              </div>
              {editable && (
                <div className={styles.bottomContent}>
                  <CustomButton
                    text={'Save Changes'}
                    disabled={!expertiseUpdateFlag}
                    className={styles.saveBtn}
                    onClick={saveExpertise}
                  />
                </div>
              )}
            </div>

            <div className={`mt-5 ${styles.contentBox}`}>
              <WorkExperienceProfile
                onUpdate={onUpdateWorkExperience}
                pageIsLoading={isLoading}
              />
            </div>
          </div>
          <div className={styles.rightContent}>
            {completedAssessment && (
              <div className={styles.assessmentResult}>
                <ResultCard />
              </div>
            )}
            <div className={`mt-5 mb-5 ${styles.resumeUploadBox}`}>
              <div className={'d-flex mb-3'}>
                <ResumeIcon color={colors.textDarkColor} />
                <h6 className={'ms-3 text-bold text-dark'}>Resume</h6>
              </div>
              {resume && (
                <div className={styles.selectedResume}>
                  <h6 className={'ms-3 text-bold gray-color-text'}>
                    Selected File
                  </h6>

                  <div className={`d-flex ${styles.borderTop}`}>
                    <div
                      className={
                        'd-flex justify-content-between align-items-center w-100'
                      }
                    >
                      <h6
                        className={
                          'text-normal gray-color-text d-flex align-items-center'
                        }
                      >
                        <span className={styles.elipse}>
                          {resume ? resume.name : ''}
                        </span>{' '}
                        <span
                          hidden={isLoading}
                          className={styles.link}
                          onClick={openResumePDFInNewTab}
                        >
                          View File
                        </span>
                      </h6>
                    </div>
                    <div className={'pointer'} onClick={() => setResume(null)}>
                      <RecycleBinIcon />
                    </div>
                  </div>
                </div>
              )}
              {resumeError && <div className={styles.error}>{resumeError}</div>}
              <div className={`mt-5 `}>
                <FileUpload
                  disabled={isLoading}
                  id={'resume'}
                  filedText={'Select file from your computer'}
                  fileSelect={async (file: File | null) => {
                    if (file !== null)
                      updateResume(await fileService.readFile(file))
                  }}
                />
                <h6 className={`gray-color-text mt-3`}>
                  Supported file type: .pdf
                </h6>
              </div>
            </div>
            <div className={`mt-5 mb-5 ${styles.resumeUploadBox}`}>
              <div className={'d-flex mb-3'}>
                <ResumeIcon color={colors.textDarkColor} />
                <h6 className={'ms-3 text-bold text-dark'}>Cover Letter</h6>
              </div>
              {coverLetter && (
                <div className={styles.selectedResume}>
                  <h6 className={'ms-3 text-bold gray-color-text'}>
                    Selected File
                  </h6>

                  <div className={`d-flex ${styles.borderTop}`}>
                    <div
                      className={
                        'd-flex justify-content-between align-items-center w-100'
                      }
                    >
                      <h6
                        className={
                          'text-normal gray-color-text d-flex align-items-center'
                        }
                      >
                        <span className={styles.elipse}>
                          {coverLetter ? coverLetter.name : ''}
                        </span>{' '}
                        <span
                          hidden={isLoading}
                          className={styles.link}
                          onClick={openCoverLetterPDFInNewTab}
                        >
                          View File
                        </span>
                      </h6>
                    </div>
                    <div
                      className={'pointer'}
                      onClick={() => setCoverLetter(null)}
                    >
                      <RecycleBinIcon />
                    </div>
                  </div>
                </div>
              )}
              {coverLetterError && (
                <div className={styles.error}>{coverLetterError}</div>
              )}
              <div className={`mt-5 `}>
                <FileUpload
                  accept={'.pdf,.docx'}
                  acceptDropTypes={[
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  disabled={isLoading}
                  id={'coverLetter'}
                  filedText={'Select file from your computer'}
                  fileSelect={async (file: File | null) => {
                    if (file !== null)
                      updateCoverLetter(await fileService.readFile(file))
                  }}
                />
                <h6 className={`gray-color-text mt-3`}>
                  Supported file type: .pdf, .docx
                </h6>
              </div>
            </div>
            <div className={styles.resumeUploadBox}>
              <div
                className={'d-flex justify-content-between align-items-center'}
              >
                <div className={'d-flex mb-3'}>
                  <ReferenceIcon color={colors.textDarkColor} />
                  <h6 className={'ms-3 text-bold text-dark'}>Referees</h6>
                </div>
                {referees.length < 3 && (
                  <CustomButton
                    icon={<PlusIcon />}
                    className={styles.widthButton}
                    onClick={() => {
                      if (!isLoading) {
                        setCurrentRefereeIndex(null)
                        setRefereeModalOpen(true)
                      }
                    }}
                  />
                )}
              </div>
              {referees.map((referee, index) => (
                <div
                  className={`mt-3 ${styles.referenceInside}`}
                  key={`referee${index}`}
                >
                  <div
                    className={`d-flex justify-content-between ${styles.borderBottom}`}
                  >
                    <div>
                      <h6 className={'gray-color-text'}>{referee.name}</h6>
                      <div className={'d-flex align-items-center'}>
                        <EmailIcon />
                        <p
                          className={`ms-2 gray-color-text text-normal font-size-semi ${styles.elipse}`}
                        >
                          {referee.email}
                        </p>
                      </div>
                      <p
                        className={'gray-color-text text-normal font-size-semi'}
                      >
                        <span className={'me-2'}>Company : </span>
                        {referee.organization}
                      </p>
                      <p
                        className={'gray-color-text text-normal font-size-semi'}
                      >
                        <span className={'me-2'}>Relationship :</span>
                        {
                          relationshipOptions.filter(
                            (fd) => fd.id === referee.relationship
                          )[0]?.value
                        }
                      </p>
                    </div>
                    <div
                      className={'d-flex flex-column justify-content-between'}
                    >
                      <div
                        onClick={() => {
                          if (!isLoading) {
                            setCurrentRefereeIndex(index)
                            setRefereeModalOpen(true)
                          }
                        }}
                      >
                        <EditIcon />
                      </div>
                      <div
                        onClick={() => {
                          if (!isLoading) {
                            removeReferee(referee.id)
                          }
                        }}
                      >
                        <RecycleBinIcon />
                      </div>
                    </div>
                  </div>
                  {referee.status === 'posted_comment' && (
                    <div>
                      <p className={'gray-color-text text-normal mt-2'}>
                        Review from {referee.name}
                      </p>
                      <h6 className={'mt-3 text-normal gray-color-text d-flex'}>
                        <span className={styles.elipse}>
                          {referee.response1}
                        </span>
                        <span
                          className={'pointer text-bold gray-color-text ms-2'}
                          onClick={() => {
                            setResponses({
                              response1: referee.response1,
                              response2: referee.response2,
                              response3: referee.response3,
                              response4: referee.response4,
                              additionalComments: referee.additionalComments,
                            })
                            setReviewModal(true)
                          }}
                        >
                          Read More
                        </span>
                      </h6>
                    </div>
                  )}
                  {referee.status === 'asked_for_review' && (
                    <div className={'mt-3 d-flex justify-content-center'}>
                      <h6 className={'text-normal'}>Review Requested</h6>
                    </div>
                  )}
                  {referee.status === 'pending' && (
                    <div className={'mt-3 d-flex justify-content-center'}>
                      <CustomButton
                        text={'Ask for a review'}
                        icon={<SendIcon />}
                        iconSide={'right'}
                        className={`text-almostBlack mt-3 ${styles.widthButton}`}
                        disabled={isLoading}
                        onClick={() => requestRefereeReview(referee.id)}
                      />
                    </div>
                  )}
                </div>
              ))}
              {refereeModalOpen && (
                <CustomModal
                  open={refereeModalOpen}
                  onCloseModal={(val) => setRefereeModalOpen(val)}
                  title={
                    currentRefereeIndex === null
                      ? 'Add New Referee'
                      : 'Update Referee'
                  }
                >
                  <AddReferenceModal
                    referees={referees}
                    currentIndex={currentRefereeIndex}
                    closeModal={() => setRefereeModalOpen(false)}
                    updateReferees={() =>
                      setRefereeUpdateFlag(!refereeUpdateFlag)
                    }
                  />
                </CustomModal>
              )}
            </div>
          </div>
        </div>
      </div>
      {reviewModal && (
        <CustomModal
          open={reviewModal}
          onCloseModal={(val) => setReviewModal(val)}
        >
          <ReviewModal
            response1={responses.response1}
            response2={responses.response2}
            response3={responses.response3}
            response4={responses.response4}
            additionalComments={responses.additionalComments}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Profile
