import React from 'react'

interface Props {
  size?: number
  color?: string
}

const CostIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 24}
      height={size ?? 24}
      fill="none"
    >
      <path
        stroke={color ?? '#9CA3AF'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14.8 9A2 2 0 0 0 13 8h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1-1.8-1M12 6v2m0 8v2m9-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  )
}

export default CostIcon
