import { useEffect, useRef } from 'react'

/**
 * CustomInputField.tsx hook for handle the click outside of the dom element
 * @param handlerFun function to handle event
 * @returns domNode reference
 */
export const useClickOutside = (handlerFun: () => void) => {
  const domNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const element = domNode.current
      if (element && !element.contains(event.target as Node)) {
        handlerFun()
      }
    }

    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return domNode
}
