import React from 'react'

interface Props {
  size?: number
  color?: string
}

const UnlockIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 30}
      height={size ? size + 9 : 39}
      fill="none"
      viewBox={'0 0 30 39'}
    >
      <path
        d="M7.667 18.666V9.5a7.333 7.333 0 1 1 14.666 0m-16.5 9.166h18.334a3.667 3.667 0 0 1 3.666 3.667v11A3.667 3.667 0 0 1 24.167 37H5.832a3.667 3.667 0 0 1-3.667-3.667v-11a3.667 3.667 0 0 1 3.667-3.666Zm11 9.167a1.833 1.833 0 1 1-3.666 0 1.833 1.833 0 0 1 3.666 0Z"
        stroke={color ?? '#F98F12'}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UnlockIcon
