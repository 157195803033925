import React from 'react'

interface Props {
  size?: number
  color?: string
}

const HireForIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ? size + 1 : 19}
      fill="none"
      viewBox={'0 0 18 19'}
    >
      <path
        d="m2.931 4.436 1.275 4.249m5.607 5.609 4.251 1.275M8.683 9.817l5.759-5.759M4 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm13 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-7-3a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        stroke={color ?? '#49B0BD'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HireForIcon
