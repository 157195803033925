import React from 'react'

interface Props {
  size?: number
  color?: string
}

const SignOutIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 21}
      height={size ? size - 2 : 18}
      fill="none"
      viewBox={'0 0 21 18'}
    >
      <path
        d="M12.5 5V3a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2m-7-4h14m0 0-3-3m3 3-3 3"
        stroke={color ?? '#CD2E00'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SignOutIcon
