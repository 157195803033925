import HttpService from '../http/http.service'
import UserService from '../user/user.service'
import { CreateQuestionRequest, SubmitRequest } from './needsanalysis.req.model'
import {
  CreateQuestionResponse,
  GetQuestionsResponse,
  RemoveQuestionResponse,
  SubmitResponse,
} from './needsanalysis.res.model'

export default class NeedsAnalysisService {
  constructor(
    private readonly httpService: HttpService = new HttpService(),
    private readonly userService: UserService = new UserService()
  ) {}

  // get questions for job ID based on task expertise
  public async getQuestionsForJob(
    jobId: string
  ): Promise<GetQuestionsResponse> {
    const res = await this.httpService.sendGetRequest<GetQuestionsResponse>(
      'needsanalysis/get/job?id=' + jobId,
      await this.userService.getUserToken()
    )
    return res
  }

  // get questions for org ID
  public async getQuestionsForOrg(
    orgId: string
  ): Promise<GetQuestionsResponse> {
    const res = await this.httpService.sendGetRequest<GetQuestionsResponse>(
      'needsanalysis/get/org?id=' + orgId,
      await this.userService.getUserToken()
    )
    return res
  }

  // Run costing model using responses to questions
  public async submitResponses(
    orgId: string,
    data: SubmitRequest[]
  ): Promise<SubmitResponse | null> {
    return this.httpService.sendPostRequest(
      'needsanalysis/submit?id=' + orgId,
      await this.userService.getUserToken(),
      data
    )
  }

  // Save responses to questions
  public async saveResponses(
    orgId: string,
    data: SubmitRequest[]
  ): Promise<SubmitResponse | null> {
    return this.httpService.sendPostRequest(
      'needsanalysis/save?id=' + orgId,
      await this.userService.getUserToken(),
      data
    )
  }

  // Adds new questions. Admin only
  public async addQuestions(
    data: CreateQuestionRequest[]
  ): Promise<CreateQuestionResponse | null> {
    return this.httpService.sendPostRequest(
      'needsanalysis/questions/create',
      await this.userService.getUserToken(),
      data
    )
  }

  // Removes questions. Admin only.
  public async removeQuestions(
    questionId: string
  ): Promise<RemoveQuestionResponse | null> {
    return this.httpService.sendPostRequest(
      'needsanalysis/questions/remove?' + new URLSearchParams(questionId),
      await this.userService.getUserToken()
    )
  }
}
