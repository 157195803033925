import React, { useEffect, useState } from 'react'
import CustomModal from '../shared/customModal/customModal'
import InfoIcon from '../../icons/info.icon'
import colors from '../../styles/variables.module.scss'

type ErrorBannerModalProps = {
  open: boolean
  onClose: (val: boolean) => void
  errorMessage?: string
}

function ErrorBannerModal({
  open,
  onClose,
  errorMessage,
}: ErrorBannerModalProps) {
  const [openModal, setOpenModal] = useState(open)

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => {
        setOpenModal(false)
        onClose(false)
      }}
    >
      <div>
        <div className={'d-flex align-items-center'}>
          <InfoIcon color={colors.dangerColor} size={30} />
          <h5 className={'text-danger ms-3'}>{errorMessage}</h5>
        </div>
      </div>
    </CustomModal>
  )
}

export default ErrorBannerModal
