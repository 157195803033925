import React from 'react'

interface Props {
  size?: number
  color?: string
}

const GreenDotIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 18} height={size ?? 19} fill="none">
      <rect y={0.5} width={18} height={18} rx={9} fill="#F0F8FA" />
      <rect
        width={10}
        height={10}
        rx={5}
        transform="matrix(-1 0 0 1 14 4.5)"
        fill={color ?? '#49B0BD'}
      />
    </svg>
  )
}

export default GreenDotIcon
