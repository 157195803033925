import { ErrorMessage, FormikValues } from 'formik'
import CustomInputField from '../shared/customInputField/CustomInputField'
import styles from './../gettingStart/GettingStart.module.scss'

interface Props {
  values: {
    how_did_you_hear_about_us?: string
    refereeName?: string
  }
  setFieldValue: (field: string, target: any) => void
  howDidYouHearAboutUsOptions: { id: string; value: string }[]
}

export const HowDidYouHearAboutUs: React.FC<Props> = ({
  values,
  setFieldValue,
  howDidYouHearAboutUsOptions,
}) => {
  return (
    <>
      <h6 className={styles.widthLeft}>How did you hear about us?*</h6>
      <div className={styles.answerLength}>
        {howDidYouHearAboutUsOptions.map((option, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              value={option.id}
              name="how_did_you_hear_about_us"
              id={`how_did_you_hear_about_us-${index}`}
              onChange={(e) => {
                setFieldValue('how_did_you_hear_about_us', e.target.value)
              }}
              checked={values.how_did_you_hear_about_us === option.id}
            />
            <label
              className="form-check-label"
              htmlFor={`how_did_you_hear_about_us-${index}`}
            >
              <h6 className={'text-normal gray-color-text'}>{option.value}</h6>
            </label>
          </div>
        ))}
        <ErrorMessage
          name="how_did_you_hear_about_us"
          component="div"
          className={styles.error}
        />

        {['Accountant Referral', 'Other'].some((ele) =>
          howDidYouHearAboutUsOptions
            ?.filter((fd) => fd.id === values.how_did_you_hear_about_us)
            ?.map((md) => md.value)
            .includes(ele)
        ) && (
          <div className="mt-3">
            <div className={styles.answerLength}>
              <CustomInputField
                name="refereeName"
                placeholder="Name *"
                onChange={(event: FormikValues) =>
                  setFieldValue('refereeName', event.target.value)
                }
                value={values.refereeName}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
