import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import styles from './../gettingStart/GettingStart.module.scss'
import { Form, Formik, FormikValues } from 'formik'
import CustomLayout from '../layout/commonLayout'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../shared/CustomButton/customButton'
import SaveIcon from '../../icons/save.icon'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import CustomInputField from '../shared/customInputField/CustomInputField'
import * as Yup from 'yup'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LeftArrow from '../../icons/leftArrow.icon'
import OrganizationService from '../../services/organization/organization.service'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import axios from 'axios'
import { Urls } from '../../context/Urls'
import { Auth } from 'aws-amplify'
import UserService from '../../services/user/user.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { ScrollToFieldError } from '../../scrollToFieldError/scrollToFieldError'
import { MasterDataContext } from '../../context/masterData'

export type CompanyDetailsProps = {
  name: string
  address: string
  industry: string
  type: string
  how_did_you_hear_about_us: string
  streetName: string
  city: string
  state: string
  postalCode: string
  referral_text: string
}

function CompanyDetails() {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [initialVal, setInitialVal] = useState<CompanyDetailsProps>({
    name: '',
    address: '',
    streetName: '',
    city: '',
    state: '',
    postalCode: '',
    industry: '',
    type: '',
    how_did_you_hear_about_us: '',
    referral_text: '',
  })
  const [orgID, setOrgID] = useState<string>('')
  const [orgTypes, setOrgTypes] = useState<any[]>([])
  const [howDidYouHearAboutUsOptions, setHowDidYouHearAboutUsOptions] =
    useState<any[]>([])
  const [industries, setIndustries] = useState<any[]>([])
  const [inputLoc, setInputLoc] = useState('')
  const [modal, setModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const masterData = useContext(MasterDataContext)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter your organisation name'),
    industry: Yup.string().required(
      'Please select your organisation’s industry sector'
    ),
    type: Yup.string().required('Please select your organisation’s type'),
    how_did_you_hear_about_us: Yup.string().required(
      'Please select how you heard about us'
    ),
    referral_text: Yup.string().when('how_did_you_hear_about_us', {
      is: '6a3d259a-7839-4710-b205-0cc669b28236',
      then: Yup.string().required('Please enter a name'),
      otherwise: Yup.string().when('how_did_you_hear_about_us', {
        is: '9f2f77a6-1cb0-4fee-a478-253f7fc2b763',
        then: Yup.string().required('Please enter a name'),
        otherwise: Yup.string(),
      }),
    }),
  })

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  useEffect(() => {
    loadingService.await(orgService.isLoggedIn()).then((res) => {
      if (!res) navigate('/signIn?type=organizationSignIn')
    })
    loadingService.await(orgService.isOrgUser()).then((res) => {
      if (!res) navigate('/')
    })
    loadingService
      .await(orgService.getOrgData())
      .then((res) => {
        if (res === null || res.status !== 'success' || res.result === null)
          return

        const address = res?.result?.address?.split(',') ?? []
        const formattedAddress = address.map((component) => component.trim())

        let streetName = ''
        let city = ''
        let state = ''
        let postalCode = ''

        if (formattedAddress.length >= 3) {
          streetName = formattedAddress[0].replace(/,/g, '') // Remove commas from streetName
          city = formattedAddress[formattedAddress.length - 3]
          state = formattedAddress[formattedAddress.length - 2]
          postalCode = formattedAddress[formattedAddress.length - 1]
        }
        setOrgID(res.result.id)
        setInputLoc(streetName ?? res.result?.address)

        setInitialVal((i) => ({
          ...i,
          name: res.result?.name ?? '',
          address: streetName ?? '',
          industry: res.result?.industry ?? '',
          type: res.result?.type ?? '',
          how_did_you_hear_about_us:
            res.result?.how_did_you_hear_about_us ?? '',
          streetName,
          city,
          postalCode,
          state,
          referral_text: res.result?.referral_text ?? '',
        }))
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [navigate, orgService, loadingService])

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (masterData?.masterData) {
      setHowDidYouHearAboutUsOptions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'how_did_you_hear_about_us')
          .sort((a: any, b: any) => a.order - b.order)
      )
      setIndustries(
        masterData.masterData
          .filter((fd: any) => fd.category === 'organization_industry')
          .sort((a: any, b: any) => a.order - b.order)
      )
      setOrgTypes(
        masterData.masterData
          .filter((fd: any) => fd.category === 'org_type')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  async function handleSubmit(values: CompanyDetailsProps) {
    try {
      if (values.streetName === '' || inputLoc === '') {
        setModal(true)
        setModalText('Street Name can not be empty!')
        return
      }

      const res = await loadingService.await(
        orgService.updateOrgData({
          name: values.name,
          address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
          industry: values.industry,
          type: values.type,
          how_did_you_hear_about_us: values.how_did_you_hear_about_us,
          referral_text: values.referral_text,
        })
      )
      if (res !== null && res.status === 'success') {
        await loadingService.await(
          userService.updateUserData({ last_page: 'jobs' })
        )
        navigate('/jobDetails', { state: { orgID, orgName: values.name } })
      } else {
      }
    } catch (error) {
      setModal(true)
      setModalText('Please enter a valid street name.')
    }
  }

  function handleBack() {
    navigate('/hireFor')
  }

  const handleSaveAndExit = async (values: CompanyDetailsProps) => {
    await handleSubmit(values)
    await loadingService.await(
      userService.updateUserData({ last_page: 'organizationDetails' })
    )
    localStorage.removeItem('masterData')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('login_form')
    await loadingService.await(Auth.signOut())
    navigate('/signIn?type=organizationSignIn')
  }

  const [locations, setLocations] = useState([])
  const [addresses, setAddresses] = useState([])
  const handleLocationChange = async (
    event: string,
    values: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    event ? setInputLoc(event) : setInputLoc(values.suburb)
    try {
      if (event === '') {
        setFieldValue('clientAddress', '')
        setFieldValue('city', '')
        setFieldValue('state', '')
        setFieldValue('postalCode', '')
        setLocations([])
        setInputLoc('')
      } else {
        setIsLoading(true)
        if (inputLoc === '') {
          setIsLoading(false)
          setLocations([])
          return
        }
        const response = await axios.get(
          `${Urls.APP_BASE_URL}/location/autocompletePlace?input=${inputLoc}&specific=true`
        )
        const arr = response.data
        setAddresses(arr)
        setLocations(
          arr.map((value: any) => {
            return {
              value: value.address,
              text: value.address,
            }
          })
        )
        setIsLoading(false)

        // Find the selected address
        const selectedAddress = arr.find((item: any) => item?.address === event)

        // Set the address values in formik
        setFieldValue('streetName', selectedAddress?.streetAddress)
        setFieldValue('city', selectedAddress?.city)
        setFieldValue('state', selectedAddress?.state)
        setFieldValue('postalCode', selectedAddress?.postalCode)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  const handleAddressSelect = async (i: any, setFieldValue: any) => {
    if (addresses && addresses.length > 0) {
      const selectedAddress: any = addresses.find(
        (item: any) =>
          item.streetAddress === i.value || item.address === i.value
      )

      if (selectedAddress) {
        setFieldValue('streetName', selectedAddress.streetAddress)
        setFieldValue('city', selectedAddress.city)
        setFieldValue('state', selectedAddress.state)
        setFieldValue('postalCode', selectedAddress.postalCode)

        setInputLoc(selectedAddress.streetAddress)
      }
    }
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <ErrorBannerModal
        errorMessage={modalText}
        open={modal}
        onClose={(val) => {
          setIsLoading(false)
          setModal(val)
        }}
      />

      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout
          organization
          title={'Organisation Details'}
          subTitle={`Tell us about the organisation who’s hiring`}
          pageName={'organisationDetails'}
          progressValue={30}
        >
          <Formik
            initialValues={initialVal}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <ScrollToFieldError />
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Organisation name</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <CustomInputField
                      name={'name'}
                      placeholder={'Organisation Name *'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('name', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Organisation address</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <div className={`mt-2 ${styles.dropdownWidth}`}>
                      <CustomDropdown
                        name={'streetName'}
                        placeHolder={'Street Name *'}
                        onChange={(e: string) => {
                          handleLocationChange(e, values, setFieldValue)
                        }}
                        dataList={locations}
                        selectedValue={{
                          value: inputLoc,
                          text: inputLoc,
                        }}
                        getSelectedItem={(i) => {
                          if (i.text) {
                            setInputLoc(i.text)
                            handleAddressSelect(i, setFieldValue)
                          }
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-between mt-2">
                      <CustomInputField
                        name={'city'}
                        placeholder={'Suburb *'}
                        className={''}
                        onChange={(event: FormikValues) =>
                          setFieldValue('city', event.target.value)
                        }
                        value={values.city}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <CustomInputField
                        name={'postalCode'}
                        placeholder={'Post Code *'}
                        className={''}
                        onChange={(event: FormikValues) =>
                          setFieldValue('postalCode', event.target.value)
                        }
                        value={values.postalCode}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <CustomInputField
                        name={'state'}
                        placeholder={'State *'}
                        className={''}
                        onChange={(event: FormikValues) =>
                          setFieldValue('state', event.target.value)
                        }
                        value={values.state}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Industry ANZSIC code</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <div className={styles.dropdownWidth}>
                      <CustomDropdown
                        disabled={isLoading}
                        dataList={industries.map((rel: any) => ({
                          value: rel.id,
                          text: rel.value,
                        }))}
                        placeHolder={'Industry *'}
                        getSelectedItem={(item) =>
                          setFieldValue('industry', item.value)
                        }
                        selectedValue={
                          industries
                            .filter((fd: any) => fd.id === values.industry)
                            .map((md: any) => ({
                              value: md.id,
                              text: md.value,
                            }))[0]
                        }
                      />
                      {errors.industry && touched.industry && (
                        <div className={styles.error}>{errors.industry}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Organisation type</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    {orgTypes.map((value: any, index) => (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="organization"
                          id="organization"
                          value={value.id}
                          onChange={(e) =>
                            setFieldValue('type', e.target.value)
                          }
                          checked={values.type === value.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="organization"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            {value.value}
                          </h6>
                        </label>
                      </div>
                    ))}
                    {errors.type && touched.type && (
                      <div className={styles.error}>{errors.type}</div>
                    )}
                  </div>
                </div>

                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>
                    How did you hear about us?
                  </h6>
                  <div className={styles.answerLength}>
                    {howDidYouHearAboutUsOptions.map(
                      (option: any, index: number) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value={option.id}
                            name="how_did_you_hear_about_us"
                            id={`how_did_you_hear_about_us-${index}`}
                            onChange={(e) => {
                              setFieldValue(
                                'how_did_you_hear_about_us',
                                e.target.value
                              )
                              setFieldValue('referral_text', '')
                            }}
                            checked={
                              values.how_did_you_hear_about_us === option.id
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`how_did_you_hear_about_us-${index}`}
                          >
                            <h6 className={'text-normal gray-color-text'}>
                              {option.value}
                            </h6>
                          </label>
                        </div>
                      )
                    )}
                    {errors.how_did_you_hear_about_us &&
                      touched.how_did_you_hear_about_us && (
                        <div className={styles.error}>
                          {errors.how_did_you_hear_about_us}
                        </div>
                      )}
                    {['Accountant Referral', 'Other'].some((ele) =>
                      howDidYouHearAboutUsOptions
                        ?.filter(
                          (fd: any) =>
                            fd.id === values.how_did_you_hear_about_us
                        )
                        ?.map((md: any) => md.value)
                        .includes(ele)
                    ) && (
                      <div className="mt-3">
                        <div className={styles.answerLength}>
                          <CustomInputField
                            name="referral_text"
                            placeholder=""
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue('referral_text', e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={
                    'd-flex justify-content-between align-items-center mt-5'
                  }
                >
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      disabled={isLoading}
                      type={'button'}
                      text={'Go Back'}
                      icon={<LeftArrow />}
                      iconSide={'left'}
                      className={styles.whiteBtn}
                      onClick={handleBack}
                    />
                    <CustomButton
                      type={'submit'}
                      text={'Save & Exit'}
                      icon={<SaveIcon />}
                      iconSide={'left'}
                      className={`ms-3 ${styles.outlineBtn}`}
                      onClick={() => handleSaveAndExit(values)}
                    />
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      type={'submit'}
                      text={'Continue'}
                      icon={<RightLongArrowIcon size={14} />}
                      iconSide={'right'}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default CompanyDetails
