type Address = {
  streetAddress: string
  suburb: string
  state: string
  postalCode: string
  country: string
}

// When address was initially created it was a single string and was mapped to
// suburb in the candidate. This is why we need to be able to move back to a string
// and handle the case where an address does not conform to the new address format
// i.e. streetAddress, state, postalCode, country
export const createAddressString = (address: Address) => {
  const { streetAddress, suburb, state, postalCode, country } = address
  return `${streetAddress.trim()}, ${suburb.trim()}, ${state.trim()}, ${postalCode}, ${country.trim()}`
}

export const handleOldAndNewAddress = (address: string): Address => {
  const addressArray = address.split(', ')
  const conformsToNewAddress = addressArray.length === 5
  if (conformsToNewAddress) {
    return {
      streetAddress: addressArray[0],
      suburb: addressArray[1],
      state: addressArray[2],
      postalCode: addressArray[3],
      country: addressArray[4],
    }
  }

  return {
    streetAddress: address,
    suburb: '',
    state: '',
    postalCode: '',
    country: '',
  }
}
