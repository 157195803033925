export const NOTICE_PERIOD_LIST = [
  { value: '0', text: "None, I'm ready now" },
  { value: '7', text: '1 week' },
  { value: '14', text: '2 weeks' },
  { value: '21', text: '3 weeks' },
  { value: '28', text: '4 weeks' },
  { value: '35', text: '5 weeks' },
  { value: '42', text: '6 weeks' },
  { value: '49', text: '7 weeks' },
  { value: '56', text: '8 weeks' },
]
export const ONSITE_DAY_LIST = [
  { value: 1, text: '1 Day' },
  { value: 2, text: '2 Days' },
  { value: 3, text: 'More than 2 days' },
]
