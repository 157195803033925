import HttpService from '../http/http.service'
import UserService from '../user/user.service'
import {
  CreateOrgRequest,
  MyobConfirmAccessRequest,
  MyobExtractCredentialsRequest,
  MyobExtractRequestDto,
  MyobGetURLRequest,
  SearchOrgRequest,
  UpdateOrgRequest,
  XeroGetURLRequest,
} from './organization.req.model'
import {
  CreateOrgResponse,
  GetOrgResponse,
  MyobConfirmAccessResponse,
  MyobExtractResponse,
  MyobGetFilesResponse,
  MyobRequestAccessResponse,
  MyobRequestStatus,
  SearchOrgResponse,
  UpdateOrgResponse,
  XeroConfirmAccessResponse,
  XeroRequestAccessResponse,
} from './organization.res.model'
import { requestQueueHandler } from '../requestQueue/requestQueueHandler'
import { RequestStatus } from '../http/http.model'

export default class OrganizationService {
  constructor(
    private readonly httpService: HttpService = new HttpService(),
    private readonly userService: UserService = new UserService()
  ) {}

  // Check if the user is logged in
  public async isLoggedIn(): Promise<boolean> {
    return this.userService.isLoggedIn()
  }

  // Check if the user is an organization user (or an admin)
  public async isOrgUser(): Promise<boolean> {
    const role = (await this.userService.getUserRole())?.role
    return role === 'org_user' || role === 'admin'
  }

  // Get the organization data from the backend
  public async getOrgData(): Promise<GetOrgResponse | null> {
    return this.httpService.sendGetRequest(
      'organization/get',
      await this.userService.getUserToken()
    )
  }

  // Create a new organization
  public async createOrg(
    data: CreateOrgRequest
  ): Promise<CreateOrgResponse | null> {
    return this.httpService.sendPostRequest(
      'organization/create',
      await this.userService.getUserToken(),
      data
    )
  }

  // Update the organization data in the backend
  public async updateOrgData(
    data: UpdateOrgRequest
  ): Promise<UpdateOrgResponse | null> {
    return this.httpService.sendPostRequest(
      'organization/update',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get the client organizations under the user from backend
  public async getClients(): Promise<SearchOrgResponse | null> {
    return this.httpService.sendGetRequest(
      'organization/client/search',
      await this.userService.getUserToken()
    )
  }

  // Search the client organizations under the user
  public async searchClients(
    params: SearchOrgRequest
  ): Promise<SearchOrgResponse | null> {
    return this.httpService.sendGetRequest(
      'organization/client/search?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  // Create a new client organization under the user
  public async createClient(
    data: CreateOrgRequest
  ): Promise<CreateOrgResponse | null> {
    return this.httpService.sendPostRequest(
      'organization/client/create',
      await this.userService.getUserToken(),
      data
    )
  }

  // Update a client organization under the user
  public async updateClient(
    params: SearchOrgRequest,
    data: UpdateOrgRequest
  ): Promise<UpdateOrgResponse | null> {
    return this.httpService.sendPostRequest(
      'organization/client/update?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      data
    )
  }

  // Get the XERO integration URL
  public async getXeroUrl(
    params: XeroGetURLRequest
  ): Promise<XeroRequestAccessResponse | null> {
    return this.httpService.sendGetRequest(
      'xero/access/request?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  // Confirm XERO access
  public async confirmXeroAccess(
    params: SearchOrgRequest,
    body: any
  ): Promise<XeroConfirmAccessResponse | null> {
    const resp: any = await this.httpService.sendPostRequest(
      'xero/access/confirm?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      body
    )
    const queResp = await requestQueueHandler(resp.key)
    return {
      status:
        queResp.status == 'success'
          ? RequestStatus.Success
          : RequestStatus.Error,
    }
  }

  // Get the MYOB integration URL
  public async getMyobUrl(
    params: MyobGetURLRequest
  ): Promise<MyobRequestAccessResponse | null> {
    return this.httpService.sendGetRequest(
      'myob/access/request?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  // Confirm MYOB access
  public async confirmMyobAccess(
    params: SearchOrgRequest,
    body: MyobConfirmAccessRequest
  ): Promise<MyobConfirmAccessResponse | null> {
    return this.httpService.sendPostRequest(
      'myob/access/confirm?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      body
    )
  }

  // Get MYOB company files
  public async getMyobCompanyFiles(
    params: SearchOrgRequest
  ): Promise<MyobGetFilesResponse | null> {
    return this.httpService.sendGetRequest(
      'myob/files?' + new URLSearchParams(params),
      await this.userService.getUserToken()
    )
  }

  // Extract data from MYOB
  public async extractMyobData(
    params: MyobExtractRequestDto,
    body: MyobExtractCredentialsRequest | null
  ): Promise<MyobExtractResponse | null> {
    const resp: any = await this.httpService.sendPostRequest(
      'myob/extract?' + new URLSearchParams(params),
      await this.userService.getUserToken(),
      body
    )
    const queResp = await requestQueueHandler(resp.key)
    return {
      status:
        queResp.status == 'success'
          ? MyobRequestStatus.Success
          : MyobRequestStatus.Error,
    }
  }
}
