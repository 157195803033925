import React from 'react'

interface Props {
  size?: number
  color?: string
}

const EmailIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 18}
      height={size ? size - 4 : 14}
      fill="none"
      viewBox={'0 0 18 14'}
    >
      <path
        stroke={color ?? '#636363'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16.5 2.833c0-.92-.746-1.667-1.667-1.667H3.167c-.92 0-1.667.747-1.667 1.667m15 0v8.334c0 .92-.746 1.666-1.667 1.666H3.167c-.92 0-1.667-.746-1.667-1.667V2.833m15 0-7.5 5-7.5-5"
      />
    </svg>
  )
}

export default EmailIcon
