import React from 'react'

interface Props {
  size?: number
  color?: string
}

const SendIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size ?? 21} height={size ?? 21} fill="none">
      <path
        d="M9 12 20 1M9 12l3.5 7a.55.55 0 0 0 1 0L20 1M9 12 2 8.5a.55.55 0 0 1 0-1L20 1"
        stroke={color ?? '#1A3665'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SendIcon
