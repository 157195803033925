export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix = '',
    result: any = []
  ) => {
    Object.keys(obj).forEach((key: any) => {
      const value = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}
